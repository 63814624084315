import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom';
import './App.css';
import {getTemplates} from './templates/templates.js';
import {EditNote} from './editnote';
import {ViewNotes} from './viewnotes';
import {TastingRooms}   from './tastingrooms'
import {Account} from "./account";
import {EditTemplate} from "./edittemplate";
import {SignIn} from "./signin";
import {Alert, Navbar, Nav, NavDropdown, Button, Container} from 'react-bootstrap'
import {useState} from 'react';
import {isInStandaloneMode,isIos, returnSvg} from './generalfunctions'
import {CompareNotes}   from "./comparenotes"
//import firebase from "firebase/compat/app"
import { initializeApp } from "firebase/app"
import {getFirestore,enableIndexedDbPersistence } from "firebase/firestore"
import {getDatabase, onValue, ref} from "firebase/database"
import {getStorage} from "firebase/storage"
import {getAuth,onAuthStateChanged,signOut} from "firebase/auth"
import {getFunctions} from "firebase/functions"
//import * as firebaseui from 'firebaseui'
//import 'firebase/compat/auth';
import {BoxArrowUp,PlusSquare,Cloud,CloudSlash} from "react-bootstrap-icons"
import {loadUserInfo,writeUserInfo} from "./firestore";
//import AddToHomescreen from "react-add-to-homescreen"


let initialUserInfo = {
    info: {
        email:'',
        displayName:"Guest",
        nickName:'',
        account:{
            type:'Guest',
            expireDate:'',
        },
        templates:['rw','ww','b','s','g'],
        lastUpdate:'',
        uniqueId: '',
    },
    customTemplateOptions:{"rw":{"s":{"clar":["extra"]}}},
    libraries:{
        "reg":[
            'Alexander Valley',
            'Bordeaux', 'Burgundy',
            'Central Coast', 'Champagne', 'Columbia Valley',
            'Diamond Mountain', 'Dry Creek Valley',
            'Green Valley',
            'Howell Mountain',
            'La Rioja', 'Lake County', 'Lodi', 'Loire Valley', 'Los Carneros',
            'Monterrey', 'Mosel',
            'Napa', 'Napa Valley', 'North Coast',
            'Paso Robles', 'Piedmont', 'Porto',
            'Rhone Valley', 'Russian River Valley', 'Rutherford',
            'Santa Cruz', 'Santa Lucia Highlands', 'Sierra Foothills', 'Sonoma', 'Sonoma Coast', 'Sonoma Valley', 'St. Helena',
            'Tuscany',
            'Walla Walla', 'Willamette Valley',
            'Yakima Valley'],
        "ven":[],
        "con":[]},
    uid:null

};

let libraryoptions = {}

const templates = getTemplates();
let noteTypes = setnotetypes(initialUserInfo);
const firebaseConfig = {
    apiKey: "AIzaSyDuY2kb31IwQ-rJI9xaMqLn49x5D829zbU",
    authDomain: "sommapp2.firebaseapp.com",
    projectId: "sommapp2",
    storageBucket: "sommapp2.appspot.com",
    messagingSenderId: "333047177330",
    appId: "1:333047177330:web:b1c3d2603ce427e48ea2eb"
};


//firebase.initializeApp(firebaseConfig)
const firebaseApp = initializeApp( firebaseConfig );

const auth = getAuth()
const functions = getFunctions(firebaseApp)
const firestoreDb = getFirestore()
enableIndexedDbPersistence(firestoreDb)
const databaseDb = getDatabase()
const storage = getStorage()

// Configure FirebaseUI.
/*
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',

    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};
*/

const App = () => {
     console.log('enterapp')

    const [online, setonline] = useState(false)
    const [user,setUser] = useState(null)
    const [mynotetypes, setmynotetypes] = useState(noteTypes)
    const [currentnotetype, setnotetype] = useState('')
    const [currentTemplateType,setCurrentTemplateType] = useState('')
    const [compareObject,updateCompareObject] = useState ({})
    const [expanded, setExpanded] = useState(false)
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [userInfo,setUserInfo] = useState(initialUserInfo)
    const [showAddTo,setShowAddTo] = useState(false)
    const [joinRooms,setjoinRooms]  = useState([])
    const [showSignIn,setShowSignIn] = useState(false)
    const [showLogin,setShowLogin] = useState(false)

    const [startDoc,setStartDoc] = useState(null)
    const [noteType,setNoteType] = useState("rw")
    const [noteTable,setNoteTable] = useState ([])
    const [filters,setFilters] = useState({})
    const viewState = [noteTable,setNoteTable,noteType,setNoteType,startDoc,setStartDoc,filters,setFilters]
    console.log("enter app after const",isSignedIn)


    // Listen to the Firebase Auth state and set the local state.
     useEffect(() => {


// This adds firebaseui to the page
// It does everything else on its own
        const startFirebaseUI = function (elementId) {
            console.log (elementId)
            //ui.start(elementId, uiConfig)
        }


        if (isIos() && !isInStandaloneMode()) setShowAddTo(true)
        const unregisterAuthObserver = onAuthStateChanged (auth, user => {
            console.log('on auth state change user',user)

            if (user) {

                console.log('auth',user)
                setUser(user)
                loadUserInfo (setUserInfo,user).then(()=>{
                    console.log('loaduserinfo',userInfo)
                    setIsSignedIn(true)
                    console.log("loaduser signed in",isSignedIn)
                })  //also sets library options

                console.log("auth has user",user,userInfo,isSignedIn)
            }
            else {
                setShowSignIn(true)
                //startFirebaseUI ('#firebaseui')

                console.log('auth no user',user)
                setIsSignedIn(false)
                setLibraryOptions(userInfo)  //defaults
                console.log("no user so signin",userInfo)
            }
            const online = dbConnected()
            setonline(online)

        });

        console.log("unregisterAuth",user)
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    useEffect(()=> {
        console.log("useEffect",userInfo)
        if(userInfo.uid) {
            const db = getDatabaseDb()
            const FBjoinRoomsref = ref(db,'users/' + userInfo.uid + '/joinRooms/')
            onValue (FBjoinRoomsref, (snapshot) => {
                const data = snapshot.val()
                const roomsArr = []
                if (data) {
                    Object.entries(data).forEach((entry) => {
                        const [key, value] = entry
                        roomsArr.push({roomName: key, roomInfo: value})
                    })
                }
                console.log('joinrooms', roomsArr)
                setjoinRooms(roomsArr)
            });
        }
    },[userInfo])

    const toggleCompareObject = (note) => {
        console.log("toggle",note,compareObject)
        if((note.key) in compareObject) {
            let clone = {...compareObject}
            delete clone[note.key]
            console.log("toggleclone",clone,compareObject)
            updateCompareObject(clone)


        }
        else {
            updateCompareObject({
                ...compareObject,
                [note.key]: note
            })
        }
        console.log("setcompare2",compareObject,note)
    }
    console.log("mainapp  issigne, showsign",userInfo, isSignedIn,showSignIn)
    return (

        <div>
            <Container fluid="xl">
           <Router>
                <div>
                    <Navbar bg="light" expand="sm" expanded={expanded}>
                        <Navbar.Brand as ={NavLink} to ="/"><span style={{color:'maroon'}}>Somm</span><span style={{color:'grey'}}>App</span></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=> setExpanded(expanded ? false : "expanded")}/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav onSelect={(tab,e) => {console.log('selectNav',tab,e);}} className="me-auto" activeKey={window.location.pathname} >
                                <Nav.Link key={"viewnote"} as ={NavLink} to ="/viewNotes" onClick={() => setExpanded(false)}>View</Nav.Link>
                                <NavDropdown key={"editnote"} title={["New",currentnotetype ? returnSvg(templates[currentnotetype].svg) : ' Note']} id="newnote-nav-dropdown" >
                                    {mynotetypes.map(type => (
                                    <NavDropdown.Item key={type.key}  as={NavLink} to={"/editnote/" + type.key} onClick={()=> {setnotetype(type.key); setExpanded(false) }} >{type.svg}{type.title}</NavDropdown.Item>
                                ))}
                                </NavDropdown>
                                <Nav.Link key={"rooms"} as ={NavLink} to ="/rooms" onClick={() => setExpanded(false)}>Rooms</Nav.Link>
                                <Nav.Link key={"account"} as ={NavLink} to ="/account" onClick={() => setExpanded(false)}>Account</Nav.Link>
                                <NavDropdown key={"template"} title={["Template",currentTemplateType ? returnSvg(templates[currentTemplateType].svg) : '']} id="edittemplate-nav-dropdown" >
                                    {mynotetypes.map(type => (
                                        <NavDropdown.Item key={"t"+type.key}  as={NavLink} to={"/editTemplate/" + type.key} onClick={()=> {setCurrentTemplateType(type.key); setExpanded(false) }} >{type.svg}{type.title}</NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                                <Nav.Link key={"compare"} as ={NavLink} to ="/compareNotes" onClick={() => setExpanded(false)}>Compare</Nav.Link>
                            </Nav>
                            <Navbar.Text>
                                {(isSignedIn) ?<span><span onClick={()=>{signOut(auth)}} >Logout</span><span>&nbsp;{(online) ? <Cloud color={"green"} size={24}/> : <CloudSlash color={"red"} size={24}/>}</span></span> : <Nav.Link key={"login"} as ={NavLink} to ="/account" onClick={() => setExpanded(false)}>SignIn</Nav.Link>}

                            </Navbar.Text>
                        </Navbar.Collapse>



                    </Navbar>
                    <Alert variant="dark" show={showAddTo} onClose={() => setShowAddTo(false)} dismissible>
                        <Alert.Heading>Install on HomeScreen</Alert.Heading>
                        <div> 1) Open Share Screen <BoxArrowUp/></div>
                        <div> 2) Tap Add to Homescreen <PlusSquare/></div>

                    </Alert>

                    {(isSignedIn) ?
                        <Switch>
                            <Route path="/rooms/:joinroom?">
                                <TastingRooms userInfo={userInfo} joinRooms={joinRooms} templates={templates}/>
                            </Route>
                            <Route path="/account">
                                <Account user={user} userInfo={userInfo} setUserInfo={setUserInfo}
                                         isSignedIn={isSignedIn} />
                            </Route>

                            <Route path="/editnote/:notetype/:notekey?">
                                <EditNote templates={templates} userInfo={userInfo} setUserInfo={setUserInfo}/>
                            </Route>
                            <Route path="/editTemplate/:type">
                                <EditTemplate templates={templates} userInfo={userInfo} setUserInfo={setUserInfo}/>
                            </Route>
                            <Route path="/compareNotes">
                                <CompareNotes templates={templates} compareObject={compareObject}
                                              toggleCompareObject={toggleCompareObject} userInfo={userInfo}/>
                            </Route>
                            <Route path="/viewNotes">
                                <ViewNotes userInfo={userInfo} templates={templates} notetypes={mynotetypes}
                                           joinRooms={joinRooms} compareObject={compareObject}
                                           toggleCompareObject={toggleCompareObject} viewState={viewState}/>
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>

                        </Switch>
                        :<div>
                            <SignIn showLogin={showLogin} setShowLogin={setShowLogin} userInfo={userInfo} setUserInfo={setUserInfo}></SignIn>


                        </div>
                    }
                    <div id="firebaseui"></div>


                </div>


            </Router>
        </Container>
        </div>
        //</FirebaseAppProvider>
   )

}




function setnotetypes (userinfo) {
    let notetypes =[];
    console.log(templates['rw'])
    for (const type of userinfo.info.templates){
        console.log(type);
        let svg = templates[type].svg;
        svg = returnSvg(templates[type].svg);
        notetypes.push({'key':type,'title':templates[type].title,'svg':svg});
    }

    console.log(notetypes)
    return notetypes;
}

function Home() {
  return (
      <div>
      <h2>Home</h2>

      </div>
  )
}
export function setLibraryOptions(userInfo) {
        Object.keys(userInfo.libraries).forEach(library => {
            libraryoptions[library] = userInfo.libraries[library].map(term => {return({"label":term,"value":term})})
        })
        console.log("libraries",userInfo,libraryoptions)

}
export function getLibraryOptions (library) {
    console.log('getlibraryoptions',library,libraryoptions)
    return (libraryoptions[library])
}
export function addLibraryTerm (key,term,userInfo,setUserInfo){
    console.log("addlib1",key,term,libraryoptions,userInfo)
    if (libraryoptions[key].length > 0) {
        const result = binarySearch(libraryoptions[key], term)
        console.log("splice", result)
        libraryoptions[key].splice(result.middle + 1, 0, {"value": term, "label": term});
    } else {
        libraryoptions[key].push({"value": term, "label": term})
    }
    let terms = libraryoptions[key].map(t => {return(t.value)})
    userInfo.libraries[key]=terms
    console.log("addlib2",terms,userInfo)
    setUserInfo(userInfo)
    writeUserInfo(userInfo)

}
function binarySearch(items, term) {

    console.log('Searching for '+term)
    let startIndex = 0;
    let stopIndex = items.length - 1;
    let middle = Math.floor((stopIndex + startIndex) / 2);

    while (items[middle].value !== term && startIndex < stopIndex) {
        console.log(middle,items[middle].value,term)
        //adjust search area
        if (term < items[middle].value) {
            stopIndex = middle - 1;
        } else if (term > items[middle].value) {
            startIndex = middle + 1;
        }

        //recalculate middle
        middle = Math.floor((stopIndex + startIndex) / 2);
    }

    // Return -1 if element is not in collection
    // return (items[middle] != value) ? -1 : middle;
    return {
        found: items[middle] === term,
        middle: middle
    }
}


export default App

function signin () {

}
function signup () {

}

export function getDefaultUserInfo(user) {
    let uI= {
        info: {
            email: user.email,
            displayName: user.displayName,
            nickName: '',
            account: {
                type: 'guest',
                expireDate: '',
            },
            templates: ['rw', 'ww', 'b', 's', 'g'],
            lastUpdate: '',
            uniqueId: '',
            userNumber:null,
        },
        customTemplateOptions: {},
        uid: user.uid,
        libraries:{
            "reg":[
                'Alexander Valley',
                'Bordeaux', 'Burgundy',
                'Central Coast', 'Champagne', 'Columbia Valley',
                'Diamond Mountain', 'Dry Creek Valley',
                'Green Valley',
                'Howell Mountain',
                'La Rioja', 'Lake County', 'Lodi', 'Loire Valley', 'Los Carneros',
                'Monterrey', 'Mosel',
                'Napa', 'Napa Valley', 'North Coast',
                'Paso Robles', 'Piedmont', 'Porto',
                'Rhone Valley', 'Russian River Valley', 'Rutherford',
                'Santa Cruz', 'Santa Lucia Highlands', 'Sierra Foothills', 'Sonoma', 'Sonoma Coast', 'Sonoma Valley', 'St. Helena',
                'Tuscany',
                'Walla Walla', 'Willamette Valley',
                'Yakima Valley'],
            "ven":[],
            "con":[],
            "food":[],
            "sreg":[]
        }
    }

        return(uI)
}

export function getFsFunctions() {
    return (functions)
}

export function getFirestoreDb() {
    return(firestoreDb)
}
export function getDatabaseDb() {
    return(databaseDb)
}
export function getStorageRef() {
    return (storage)
}
export function getAuthRef() {
    return (auth)
}
export async function dbConnected () {
    const db = getDatabaseDb()
    const connectedRef = ref(db, ".info/connected")
    onValue(connectedRef, (snap) => {
        if (snap.val()) {
            return(snap.val())
        }
    });
}

//            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
