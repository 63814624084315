import React, {useState,useMemo,useEffect,useRef} from "react";
import {useParams} from "react-router-dom";
import {buildNoteTitle, getLocation, getReverseTime} from "./generalfunctions";
import {Tabs,Tab,Form,Row,Col,Modal,Button,Image} from "react-bootstrap";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { Pencil, PencilFill,Keyboard} from 'react-bootstrap-icons';
import {getLibraryOptions,addLibraryTerm,getStorageRef} from './App'
import {FSloadNote,FSaddNote,FSdeleteNote} from "./firestore";
import {ref,uploadBytesResumable,getDownloadURL} from "firebase/storage";
import {Prompt} from "react-router"
import {BsCheck} from "react-icons/all";

let mainnote = {"key":null};
let editkey = null


export function EditNote({templates,userInfo,setUserInfo}) {
    let {notetype, notekey} = useParams();
    console.log('edit1',notetype,notekey)
    const fieldOptions = useMemo(() => generateFieldArray(templates, notetype), [templates, notetype]);
    const thisTemplate = templates[notetype];
    const dcatkeys = {}
    thisTemplate.categories.forEach(category => {dcatkeys[category.key] = {}})
    const ncatkeys = {}
    thisTemplate.categories.forEach(category => {ncatkeys[category.key] = {}})
    const [thisNoteState, setthisNote] = useState( {"search":{},"data":dcatkeys,"notes":ncatkeys,"key":"","type":notetype,"title":"","time":getReverseTime(),"kw":''})
    const [activeTab,setactiveTab] = useState("save")
    const [doneTabs,setDoneTabs] = useState({})

    console.log('edit', notekey,mainnote,notetype,userInfo,doneTabs)

    useEffect(() => {
        if (notekey) {   //edit existing note
            editkey = notekey   //used for delete
            FSloadNote(notekey,"users/"+userInfo.uid+"/notes/")
                .then(notedata => {
                    mainnote = notedata
                    mainnote["time"] = Date.now()
                    mainnote.key = String(getReverseTime())
                    setthisNote(notedata)
                    setactiveTab(thisTemplate.defaultTab)
                })
        }
        else{  //start new note
            editkey = null
            mainnote = {"search":{},"data":dcatkeys,"notes":ncatkeys,"key": String(getReverseTime()),"title":"","type":notetype,"time":Date.now(),"kw":''}
            setthisNote(mainnote)
            getLocation().then(geo => {
               // console.log('position',geo["loc"],geo["coords"])
                mainnote["geo"] = geo
                if (geo.loc.city) mainnote.data.info.loc = geo["loc"]["city"]+", "+geo["loc"]["state"]+", "+geo["loc"]["zip"]
                else mainnote.data.info.loc = geo.loc //error
                setthisNote(mainnote)
                setactiveTab(thisTemplate.defaultTab)
            })
        }
    }, [notetype])
   // return( <RenderEditNote thisNoteState={thisNoteState} setthisNote={setthisNote} thisTemplate={thisTemplate} fieldOptions={fieldOptions} notekey={notekey} activeTab={activeTab} setactiveTab={setactiveTab} />)

//}
//const RenderEditNote = ({thisNoteState,setthisNote,thisTemplate,fieldOptions,notekey,activeTab,setactiveTab}) => {
    // thisTemplate.defaultTab
    //const [activeTab,setactiveTab] = useState (forceTab)
    const [noteChanged,setnoteChanged] = useState(false)
/*
    useEffect( () => {
        if (mainnote.key) {
            setthisNote(mainnote)
            console.log('reset note', mainnote, notekey)

        }
    },[notekey])
*/
    const handleUpdate = (category,field,value,note,searchkey,searchterm) => {
        console.log('handle',field,value,note)
         if (value) mainnote.data[category][field] = value
         console.log(mainnote.data[category],Object.keys(mainnote.data[category]).length)
        let doneCount = thisTemplate.fields[category].fieldcount
        if (!mainnote.data[category].cu) {console.log('-1',doneCount); doneCount = doneCount-1}
        console.log(doneCount,category)
        if (Object.keys(mainnote.data[category]).length === doneCount) {
            setDoneTabs({...doneTabs,[category]:true})
            console.log('doneTabs',doneTabs)
        }

         if (note) {
             console.log ('do not be here',note)
             mainnote.notes[category][field] = note
         }
         if (searchkey) mainnote.search[searchkey] = searchterm
         setnoteChanged(true)
    }

    function handleTabChange(ev)  {
        console.log('tabchange',mainnote)
        setthisNote(mainnote);
        setactiveTab(ev)
     }
     const getTitle=(category) =>{
        console.log(category)
        let style = {}
         if (doneTabs[category.key]) style = {textDecoration:"underline",textDecorationColor:"green",textDecorationThickness:2}
        return(
            <span style={style}>{category.name}</span>
        )
     }

    return (
        <div>
            <Prompt when={noteChanged} message="You have unsaved changes.  Are you sure you want to leave this page?"/>
        <Tabs onSelect={(ev) => handleTabChange(ev)} mountOnEnter={true}  activeKey = {activeTab} unmountOnExit={true}  id="edit-wine-tabs" >
            {thisTemplate.categories.map(category => {
                return(
                <Tab eventKey={category.key}  tabClassName={"px-1 px-sm-3 link-primary"} title={getTitle(category)} key={category.key}>
                    <Form onSubmit={e => e.preventDefault()}>
                        {thisTemplate.fields[category.key].fieldorder.map((fieldkey) => {
                            const fieldinfo = thisTemplate.fields[category.key].fieldarray[fieldkey];
                            const ckey = category.key
                            let value = (thisNoteState["data"][ckey][fieldkey]) ? thisNoteState["data"][ckey][fieldkey] : ''
                            let note = (thisNoteState["notes"][ckey][fieldkey]) ? thisNoteState["notes"][ckey][fieldkey] : null
                            return(
                                    <div className={"input-row"}>
                                    <SommInput handleupdate={handleUpdate} fieldoptions={fieldOptions[ckey][fieldkey]} fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} categorykey={ckey} note={note} userInfo={userInfo} setUserInfo={setUserInfo} noteKey={mainnote.key}/>
                                    </div>
                            )
                            })
                        }
                   </Form>
                </Tab>
                )}
            )}

            <Tab key={"save"} eventKey={"save"} title={SaveTab(noteChanged)} >
                {(noteChanged) ? <SaveModal noteChanged={noteChanged} template={thisTemplate} userInfo={userInfo} /> : <span> </span>}

            </Tab>
        </Tabs>
        </div>
    )
}

const SaveTab = (noteChanged) => {
    if (noteChanged) {
        return(<span style={{backgroundColor:"maroon",color:"white",border:".4rem solid maroon", borderRadius:".2rem"}}>Save</span>)
    }
    else {
        return(<span style={{color:"lightgrey"}}>Save</span>)
    }
}
const SaveModal = ({noteChanged,template,userInfo}) => {
    console.log('save',mainnote,noteChanged,template);
        const [modalOn,setmodalOn] = useState(noteChanged)
        const [noteTitle,setnoteTitle] = useState(buildNoteTitle(mainnote,template))
        const hideModal = () => {
            setmodalOn(false);
        };
        let notewords = mainnote.data.info.title.split(/\s+/)
    console.log (notewords)
        let notekeys = []
        notewords.forEach( (word) => {
            console.log(word)
            let shortword = word.substring(0,3).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            if (shortword.length === 3) notekeys.push(shortword)
            notekeys.push((shortword + " ").substring(0,2)) // add a space so 1 char because 1char +space for two char search

        })
        const saveNote = () => {
           mainnote.title = noteTitle
            mainnote.kw = notekeys
            mainnote.rtime = getReverseTime()
            console.log('write',mainnote,userInfo.uid,editkey)
            const path="users/"+userInfo.uid+"/notes/"
            FSaddNote(mainnote,path)
            if (editkey) FSdeleteNote(editkey,path)         //we start a new note no matter what for time and cloud storage
            setmodalOn(false)
        }
        //text.match(/\b(\w+)'?(\w+)?\b/g)
        const customTitle = (ev) => {
            mainnote.customtitle = !mainnote['customtitle'];
            setnoteTitle(buildNoteTitle(mainnote,template))
        }
    return(
        <Modal show={modalOn} onHide={hideModal} >
            <Modal.Body>
                <div>Title will be: {noteTitle}</div>
                <div>
                        <Form.Check onClick={(ev) => customTitle(ev.target.value)} type="checkbox" label="Use only my title input" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" onClick={hideModal}>Cancel</Button>
                <Button size="sm" onClick={saveNote}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}
const SommInput = ({fieldoptions,categorykey,fieldinfo,fieldkey,value,handleupdate,note,userInfo,noteKey,setUserInfo}) => {
    console.log(fieldkey)
    switch (fieldinfo.style) {
        case "t": //text
        case "tn":  //text as number
            return (<SommTextInput categorykey={categorykey} key={fieldkey} fieldinfo={fieldinfo} fieldkey={fieldkey}
                                   value={value} handleUpdate={handleupdate}/>)

        case "ta": //text area
            return (<SommAreaInput categorykey={categorykey} key={fieldkey} fieldinfo={fieldinfo} fieldkey={fieldkey}
                                   value={value} handleUpdate={handleupdate}/>)

        case "sm":  //multiselect
            return (<SommMultiSelectInput categorykey={categorykey} fieldoptions={fieldoptions} key={fieldkey}
                                          fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                          handleUpdate={handleupdate}/>)

        case "sr": //single select with range
            return (<SommSingleSelectInputRange categorykey={categorykey} fieldoptions={fieldoptions} key={fieldkey}
                                                fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                                handleUpdate={handleupdate}/>)

        case "ss":  //single select
            return (<SommSingleSelectInput categorykey={categorykey} fieldoptions={fieldoptions} key={fieldkey}
                                           fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                           handleUpdate={handleupdate}/>)

        case "sb":  //single select with buttons
            return (<SommSingleSelectInputButton categorykey={categorykey} fieldoptions={fieldoptions} key={fieldkey}
                                                 fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                                 handleUpdate={handleupdate}/>)

        case "tl":  //text learning field uses multiselect
            return (<SommSingleSelectLearning categorykey={categorykey}  key={fieldkey}
                                                 fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                                 handleUpdate={handleupdate} userInfo={userInfo} setUserInfo={setUserInfo}/>)
        case "i": //image
            return (<SommLoadImage categorykey={categorykey}  key={fieldkey}
                                   fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                   handleUpdate={handleupdate} userInfo={userInfo} noteKey={noteKey}/>)
        case "f": //file
            return (<SommLoadFile categorykey={categorykey}  key={fieldkey}
                                   fieldinfo={fieldinfo} fieldkey={fieldkey} value={value} note={note}
                                   handleUpdate={handleupdate} userInfo={userInfo} noteKey={noteKey}/>)

    }
    return null

}

const SommLoadFile = ({fieldinfo,fieldkey,value,categorykey,handleUpdate,userInfo,noteKey}) => {
    console.log("loadfile",value)
    const [localValue,setLocalValue] = useState(value)
    if (localValue==="") setLocalValue({url:"",fileName:""})
    const fileUploadHandler = (e) =>{
        handleUpload(e.target.files[0])
    }
    const handleUpload = (file) => {
        const storage = getStorageRef();
        const storageRef = ref(storage,"/images/"+ userInfo.uid +"/"+noteKey+"/file1")
        const uploadTask = uploadBytesResumable(storageRef,file)
        uploadTask.on("state_changed", console.log, console.error, () => {
            getDownloadURL(storageRef)
                .then((url) => {
                    const val={url:url,fileName:file.name}
                    handleUpdate(categorykey,fieldkey,val,null,fieldinfo.skey,val);
                    setLocalValue(val)
                });
        });
    }
    return (
        <div>
            <Form.Group as={Row} controlId={"form-"+fieldkey} key={fieldkey}>
                <Form.Label column sm={3} lg={2}>
                    <BsCheck style={{strokeWidth:2}} color={(localValue.url)?"green":"white"}/>{fieldinfo.title}
                </Form.Label>
                <Col >
                    <Form.Label htmlFor="loadFile"><span style={{border:"solid 1px black"}}> Load </span> {localValue.fileName}</Form.Label>
                    <Form.Control type="file" style={{display: "none"}} id="loadFile"  onChange={(e)=>fileUploadHandler(e)}/>

                </Col>
            </Form.Group>
        </div>
    )
}

const SommLoadImage = ({fieldinfo,fieldkey,value,categorykey,handleUpdate,userInfo,noteKey}) => {
    console.log(noteKey)
    const [localValue,setLocalValue] = useState(value)
    if (localValue==="") setLocalValue({url:"",fileName:"",ts:""})

    const fileUploadHandler = (e) =>{
        console.log("file",e.target.files,userInfo)
        handleUpload(e.target.files[0])
    }

    function handleUpload(file) {
        console.log('Handleupload',file)
        const storage = getStorageRef()
        const storageRef = ref(storage,"/images/"+ userInfo.uid +"/"+noteKey+"/image")
        const uploadTask = uploadBytesResumable(storageRef,file)
        uploadTask.on ("state_changed", console.log, console.error, () => {
            getDownloadURL(storageRef)
                .then((url) => {
                    const val = {url: url, fileName: file.name}
                    handleUpdate(categorykey, fieldkey, val, null, fieldinfo.skey, val);
                    setLocalValue(val)
                });
        })
    }
    return (
        <div>
            <Form.Group as={Row} controlId={"form-"+fieldkey} key={fieldkey}>
                <Form.Label column sm={3} lg={2}>
                    <BsCheck style={{strokeWidth:2}} color={(localValue.url)?"green":"white"}/>{fieldinfo.title}
                </Form.Label>
                <Col >
                    <Form.Label htmlFor="loadImage"><span style={{border:"solid 1px black"}}> Load </span> {localValue.fileName}</Form.Label>
                    <Form.Control type="file" style={{display:"none"}} id="loadImage" onChange={(e)=>fileUploadHandler(e)}/>


         {(localValue.url !== "") ? <Image src={localValue.url} thumbnail /> :""}
                </Col>
            </Form.Group>
        </div>

    )
}

const SommTextInput = ({fieldinfo,fieldkey,value,categorykey,handleUpdate}) => {
    const [textValue,settextState] = useState(value);
    const updateTextState = (v) => {
        settextState(v)
        let vkey = v
        if (fieldinfo.style === "tn") vkey = 1*v  // convert to number
        handleUpdate(categorykey,fieldkey,v,null,fieldinfo.skey,vkey);
    }
    console.log('text',value)
    let placeholder = fieldinfo.placeholder ? fieldinfo.placeholder : '';
    return (
        <Form.Group as={Row} controlId={"form-"+fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(textValue)?"green":"white"}/>{fieldinfo.title}
            </Form.Label>
            <Col >
                <Form.Control key={fieldkey} value={textValue} type="text" placeholder={placeholder} onChange={(ev) => updateTextState(ev.target.value)}/>
            </Col>
        </Form.Group>
    );
}

const SommAreaInput = ({fieldinfo,fieldkey,value,categorykey,handleUpdate}) => {
    const [textValue,settextState] = useState(value);
    const updateTextState = (value) => {
        settextState(value)
        handleUpdate(categorykey,fieldkey,value,null,fieldinfo.searchkey,value);
    }
    let placeholder = fieldinfo.placeholder ? fieldinfo.placeholder : '';
    return (
        <Form.Group as={Row} controlId={"form-"+fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(textValue)?"green":"white"}/>{fieldinfo.title}
            </Form.Label>
            <Col >
                <Form.Control key={fieldkey} value={textValue} as={"textarea"} rows={4} placeholder={placeholder} onChange={(ev) => updateTextState(ev.target.value)}/>
            </Col>
        </Form.Group>
    );
}

const SommSingleSelectForm = ({selectState,fieldoptions,updateSelect}) => { // used by all single select input types
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#999',
            'fontSize':'.7rem'
        }),
        menu: (provided) => ({
            ...provided,
            "z-index":10,
            "backgroundColor":"#ddd"

        }),
        option: (provided) => ({
            ...provided,
            "display":"inline-block",
            "width":"auto"
        }),
        group: (provided) => ({
            ...provided,
            "float":"none"
        }),
    }
    console.log(customStyles)
    return (

        <Select  isMulti

                 components={{ IndicatorsContainer:() => null }}
                 isSearchable={false}
                 value={selectState}
                 placeholder ={"Single Select"}
                 options={selectState == null ? fieldoptions : selectState.length === 1 ? [] : fieldoptions}
                 noOptionsMessage={() => {
                     return  selectState == null ? false : selectState.length === 1 ? 'One Choice Only' : 'No Options';
                 }}
                 onChange={(valobj) => updateSelect(valobj)}
                 styles={customStyles}
                 theme={theme => ({
                     ...theme,
                     colors: {
                         ...theme.colors,
                         primary: 'rgba(128,0,0,1)',
                         primary25:'rgba(128,0,0,.25)',
                         primaty50:'rgba(128,0,0,.50)',
                         primary75:'rgba(128,0,0,.75)',
                     },
                 })}
        />)
}

const SommSingleSelectInputRange = ({fieldoptions,fieldinfo,fieldkey,value,categorykey,handleUpdate,note}) => {

    //use value from above only for init (existing note)
    let initrange = 0;
    let initselect = [];
    if (value) {
        if ( value.length) initrange = fieldoptions[0].options.find(x => x.value === value[0]).index+1;  //assumes only one array
        initselect = [fieldoptions[0].options[initrange-1]]
        console.log ('init',value,initrange,initselect,fieldoptions);
    }
    const [rangeState,setrangeState] = useState(initrange);
    const [selectState,setselectState] = useState(initselect);
    const  numberoptions= fieldoptions[0].options.length;

    const updateNoteSelectState = (valuestate) => {
        let selectdata = []
        if (valuestate.length > 0 ) selectdata =[valuestate[0].value];
        handleUpdate(categorykey,fieldkey,selectdata,null,fieldinfo.skey,selectdata[0]);
    }

    const updateRange = (value) =>{
        setrangeState(Number(value));
        let selectobject = [];
        if (Number(value) > 0) selectobject = [fieldoptions[0].options[Number(value-1)]]
        setselectState(selectobject);
        updateNoteSelectState(selectobject);
    }
    const updateSelect = (valuesel) => {
        setselectState(valuesel);
        let rangevalue = 0;
        if (valuesel.length > 0) rangevalue = valuesel[0].index+1;
        setrangeState(rangevalue);
        updateNoteSelectState(valuesel);
    }
    return (
        <Form.Group as={Row} controlId={"form-" + fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(selectState.length)?"green":"white"}/>{fieldinfo.title}
                < AddNoteModal  fieldkey={fieldkey} categorykey={categorykey} label={fieldinfo.title} handler={handleUpdate} note={note}/>
            </Form.Label>
            <Col>
                  <SommSingleSelectForm selectState={selectState} fieldoptions={fieldoptions} updateSelect={updateSelect} />
            </Col>
            <Col className="d-sm-block d-none" sm={5}>
                <Form.Group controlId="formBasicRangeCustom">
                    <Form.Range type="range" custom
                                  key={fieldkey}
                                  value={rangeState}
                                  min={0}
                                  max={numberoptions}
                                  onChange={(ev) => updateRange(ev.target.value)}/>
                </Form.Group>
            </Col>
        </Form.Group>
    )
}

const SommSingleSelectInputButton = ({fieldoptions,fieldinfo,fieldkey,value,categorykey,handleUpdate,note}) => {

    //use value from above only for init (existing note)
    let initbutton = null;
    let initselect = [];
    if (value) {
        if ( value.length) initbutton = fieldoptions[0].options.find(x => x.value === value[0]).index;  //assumes only one array
        initselect = [fieldoptions[0].options[initbutton]]
    }
    const [buttonState,setbuttonState] = useState(initbutton);
    const [selectState,setselectState] = useState(initselect);

    const updateNoteSelectState = (valuestate) => {
        let selectdata = []
        if (valuestate.length > 0 ) selectdata =[valuestate[0].value];
        handleUpdate(categorykey,fieldkey,selectdata,null,fieldinfo.skey,selectdata[0]);
    }

    const updateRadio = (index,value) =>{
        setbuttonState(index)
        let selectobject = [fieldoptions[0].options[Number(index)]]
        setselectState(selectobject);
        updateNoteSelectState(selectobject);
    }
    const updateSelect = (valuesel) => {
        setselectState(valuesel);
        let buttonvalue = null;
        if (valuesel.length > 0) buttonvalue = valuesel[0].index;
        setbuttonState(buttonvalue);
        updateNoteSelectState(valuesel);
    }

    return (
        <Form.Group as={Row} controlId={"form-" + fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(selectState.length)?"green":"white"}/>{fieldinfo.title}
                < AddNoteModal fieldkey={fieldkey} categorykey={categorykey} label={fieldinfo.title} handler={handleUpdate} note={note}/>
            </Form.Label>

            <Col >
                <SommSingleSelectForm selectState={selectState} fieldoptions={fieldoptions} updateSelect={updateSelect} />
            </Col>
            <Col className="d-sm-block d-none" sm={5} >
                <Form.Group controlId="formButtons" className={"radio-buttons"}>
                    {
                        fieldoptions[0].options.map((option,index) => {
                        return (

                            <Form.Check  key={option.label} inline name="buttongroup" custom label={option.label} type="radio" id={`inline-${option.label}-1`} checked={buttonState === index}
                                        onChange={(ev) => updateRadio(index,ev.target.value)}  />
                        )
                        })}
                </Form.Group>
            </Col>

        </Form.Group>
    )
}

const SommMultiSelectInput = ({fieldoptions,fieldinfo,fieldkey,value,categorykey,handleUpdate,note}) => {

    let initselect = [];
    if (value) {
        value.forEach(item =>{
            initselect.push({"label":item,"value":item})
        })
    }
    const [selectState,setselectState] = useState(initselect)
    const [searchable,setsearchable] = useState(false)  //searchable causes keyboard on ios.  So hide unless new dropdownindicator is pressed
    const selectRef = useRef();
    const updateSelect = (valuesel) => {
        setselectState(valuesel);
        updateNoteSelectState(valuesel);
    }
    const updateNoteSelectState = (valuestate) => {
        let selectdata = []
        if (valuestate.length > 0 ) {
            valuestate.forEach(selectobj => {
                selectdata.push(selectobj.value)
            });
        }
        handleUpdate(categorykey,fieldkey,selectdata,null,fieldinfo.skey,selectdata[0]); //note search key is only first value for multi
    }
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#999',
            'fontSize':'.7rem'
        }),
        menu: (provided) => ({
            ...provided,
            "z-index":10
        }),
        option: (provided) => ({
            ...provided,
            "display":"inline-block",
            "width":"auto"
        }),
        group: (provided) => ({
            ...provided,
            "float":"none",
            "backgroundColor":"#ddd"
        }),
    }
    const delay = t => new Promise(resolve => setTimeout(resolve, t));
    const changedropdown = (base) => {
        return <div style={{cursor:'pointer'}} onPointerDown={(e) => {
            console.log(selectRef)
            if (searchable) return
            setsearchable(true)
            e.stopPropagation()

            delay(500).then(() => {
                console.log('delay',selectRef)
                selectRef.current.focus()
            })
        }}><Keyboard size={24}/></div>
    }

    return (
        <Form.Group as={Row} controlId={"form-" + fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(selectState.length)?"green":"white"}/>{fieldinfo.title}
                < AddNoteModal fieldkey={fieldkey} categorykey={categorykey} label={fieldinfo.title} handler={handleUpdate} note={note}/>
            </Form.Label>

            <Col >


                <CreatableSelect  isMulti
                                  ref= {ref => {selectRef.current = ref}}

                                  isSearchable={searchable}
                                       //components={{ IndicatorsContainer:() => null }}
                                  components={{DropdownIndicator: base => changedropdown({...base}) } }
                                  styles={customStyles}
                                  theme={theme => ({
                                      ...theme,
                                       colors: {
                                          ...theme.colors,
                                          primary: 'rgba(128,0,0,1)',
                                           primary25:'rgba(128,0,0,.25)',
                                           primaty50:'rgba(128,0,0,.50)',
                                           primary75:'rgba(128,0,0,.75)',
                                      },
                                  })}
                                  options = {fieldoptions}
                                  value={selectState}
                                  onChange={(valobj) => updateSelect(valobj)}
                                  placeholder = {"Multiple Select and Type to Add/Filter"}

                />

            </Col>
        </Form.Group>
    )
}

const AddNoteModal = ({categorykey,fieldkey,label,handler,note}) => {
    const [modalOn,setmodalOn] = useState(false)
    const [noteSavedText,setnoteSavedText] = useState(note)
    const [modalText,setmodalText] = useState(note)
    const hideModal = () => {
        setmodalOn(false);
    };

    return (
        <span>
            <span  onClick={() => setmodalOn(true)}>{(noteSavedText)? <PencilFill style={{color:"maroon",width:16}} /> : <Pencil  />} </span>
            <Modal backdrop="static" show={modalOn} onHide={() => {console.log('hide'); hideModal(); setmodalText(noteSavedText) }}>
                <Modal.Body>
                    <div>Add a short note for field {label}.</div>
                    <div>
                        <Form.Control key={fieldkey} value={modalText} autoFocus type="text" onChange = {(ev) => setmodalText(ev.target.value)}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" onClick={() =>{hideModal(); setmodalText(noteSavedText)}}>Cancel</Button>
                    <Button size="sm" onClick={() => {hideModal(); setnoteSavedText(modalText); handler(categorykey,fieldkey,null,modalText,null,null)}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </span>
        )
}

const SommSingleSelectInput = ({fieldoptions,fieldinfo,fieldkey,value,categorykey,handleUpdate,note}) => {

    let initselect = [];
    if (value) {
        value.forEach(item =>{
            initselect.push({"label":item,"value":item})
        })
    }
    const [selectState,setselectState] = useState(initselect);
    const updateSelect = (valuesel) => {
        setselectState(valuesel);
        updateNoteSelectState(valuesel);
    }
    const updateNoteSelectState = (valuestate) => {
        let selectdata = []
        if (valuestate.length > 0 ) {
            valuestate.forEach(selectobj => {
                selectdata.push(selectobj.value)
            });
        }
        handleUpdate(categorykey,fieldkey,selectdata,null,fieldinfo.skey,selectdata[0]);
    }

    return (
        <Form.Group as={Row} controlId={"form-" + fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(selectState.length)?"green":"white"}/>{fieldinfo.title}
                < AddNoteModal fieldkey={fieldkey} categorykey={categorykey} label={fieldinfo.title} handler={handleUpdate} note={note}/>
            </Form.Label>

            <Col >
                <SommSingleSelectForm selectState={selectState} fieldoptions={fieldoptions} updateSelect={updateSelect} />
            </Col>
        </Form.Group>
    )
}

const SommSingleSelectLearning = ({fieldinfo,fieldkey,value,categorykey,handleUpdate,note,userInfo,setUserInfo}) => {

    let initselect = [];
    if (value) {
        value.forEach(item =>{
            initselect.push({"label":item,"value":item})
        })
    }
    const [selectState,setselectState] = useState(initselect);

    const updateSelect = (valuesel) => {
        setselectState(valuesel);
        updateNoteSelectState(valuesel);
    }
    const updateNoteSelectState = (valuestate) => {
        let selectdata = []
        if (valuestate.length > 0 ) {
            valuestate.forEach(selectobj => {
                selectdata.push(selectobj.value)
            });
        }
        handleUpdate(categorykey,fieldkey,selectdata,null,fieldinfo.skey,selectdata[0]);
    }
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#999',
            'fontSize':'.7rem'
        }),
        menu: (provided) => ({
            ...provided,
            "z-index":10,
            "backgroundColor":"#ddd"

        }),
        option: (provided) => ({
            ...provided,
            "display":"inline-block",
            "width":"auto"
        }),
    }
    let fieldoptions = getLibraryOptions(fieldkey)
    return (
        <Form.Group as={Row} controlId={"form-" + fieldkey} key={fieldkey}>
            <Form.Label column sm={3} lg={2}>
                <BsCheck style={{strokeWidth:2}} color={(selectState.length)?"green":"white"}/>{fieldinfo.title}
                < AddNoteModal fieldkey={fieldkey} categorykey={categorykey} label={fieldinfo.title} handler={handleUpdate} note={note}/>
            </Form.Label>

            <Col >
                <CreatableSelect
                    isMulti
                components={{ IndicatorsContainer:() => null }}
                isSearchable={true}
                onCreateOption ={ (value) => {console.log("adding new",value); addLibraryTerm(fieldkey,value,userInfo,setUserInfo);updateSelect({"label":value,"value":value})}}
                value={selectState}
                placeholder ={"Learning"}
                options={selectState == null ? fieldoptions : selectState.length === 1 ? [] : fieldoptions}
                noOptionsMessage={() => {return  selectState == null ? false : selectState.length === 1 ? 'One Choice Only' : 'No Options'}}
                    getOptionValue ={option => option.label}
                    onChange={(valobj) => updateSelect(valobj)}
                styles={customStyles}
                theme={theme => ({
                ...theme,
                colors: {
                ...theme.colors,
                primary: 'rgba(128,0,0,1)',
                primary25:'rgba(128,0,0,.25)',
                primaty50:'rgba(128,0,0,.50)',
                primary75:'rgba(128,0,0,.75)',
            },
            })}
                />

            </Col>
        </Form.Group>
    )
}
function generateFieldArray (templates,notetype) {
    let fieldOptions = {};
    templates[notetype].categories.forEach((category) => {
        fieldOptions[category.key] = {};
        templates[notetype].fields[category.key].fieldorder.forEach((fieldkey) => {
            let fieldinfo = templates[notetype].fields[category.key].fieldarray[fieldkey];
            let selectoptions = [];
            if (fieldinfo.options) {
                let optarr = templates[fieldinfo.options[0]].options[fieldinfo.options[1]].optArr;
                optarr.forEach((option) => {
                    let o = [];
                    option.options.forEach((suboption, index) => {
                        o.push({'value': suboption, 'label': suboption,'index':index})
                    })
                    selectoptions.push({'label': option.label, 'options': o})
                })

            }
            fieldOptions[category.key][fieldkey] = selectoptions;

        });

    });
    return (fieldOptions);
}
