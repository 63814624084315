import React from "react";
import {returnSvg, stringReverseDate} from "./generalfunctions"
import {Image,Card, Button, Col, Row, Container, Table} from "react-bootstrap";
import sanitize from "sanitize-filename"
import fileDownload from "js-file-download";
import {httpsCallable} from "firebase/functions"
import {getFsFunctions} from "./App";



export function ShowNote({noteData, templates,blind=false}) {
     console.log("ShowNote",templates)

    const note=noteData
    const thisTemplate = templates[note.type]
    const notedisplay = templates[note.type].notedisplay

    if (note.key) return (
        <Container fluid>
            <Row>
                <Col xs={7}> {!blind ? <h4>{returnSvg(thisTemplate.svg)} {note.title} <span style={{fontSize:".8em"}}> {stringReverseDate(note.rtime)}</span></h4> : <h4>Blind Note <span style={{fontSize:".8em"}}> {stringReverseDate(note.rtime)}</span></h4>}</Col>
                <Col xs={4}> <Button variant="outline-primary" size="sm" id="save" onClick={()=>printPDF(thisTemplate.svg,note,thisTemplate,false,'save')}>Save PDF</Button> &nbsp;
                            <Button variant="outline-primary" size="sm" id="print" onClick={()=>printPDF(thisTemplate.svg,note,thisTemplate,true,'print')}>Print PDF</Button>
                </Col>
            </Row>
            {notedisplay.map (item => {
                return  (
                    <CategoryRow item={item} note={note} thisTemplate={thisTemplate} />
                )
            })

            }
            <ExtraNotes note={note} thisTemplate={thisTemplate} />
       </Container>

    )
     else return <div>Loading</div>
}

//[[["s", "n"], "p"], ["i", "f"], "info"] is the most complex pattern, so no more than 2 wide or deep.
const CategoryRow  =({item,note,thisTemplate})  => {
    if (Array.isArray(item)) {
        if (Array.isArray(item[0])) {
            return (
                <Row>
                    <Col>
                        <CategoryCard ckey={item[0][0]} note={note} thisTemplate={thisTemplate}/>
                        <CategoryCard ckey={item[0][1]} note={note} thisTemplate={thisTemplate}/>
                    </Col>
                    <Col >
                        <CategoryCard ckey={item[1]} note={note} thisTemplate={thisTemplate}/>
                    </Col>
                </Row>
        )
        }
        else {
            return (
                <Row>
                    <Col>
                        <CategoryCard ckey={item[0]} note={note} thisTemplate={thisTemplate}/>
                    </Col>
                    <Col >
                        <CategoryCard ckey={item[1]} note={note} thisTemplate={thisTemplate}/>
                    </Col>
                </Row>
        )
        }
    } else
    {
        return (
            <Row>
                <Col >
                    <CategoryCard ckey={item} note={note} thisTemplate={thisTemplate} />
                </Col>
            </Row>
        )
    }
}

const CategoryCard = ({ckey, note, thisTemplate}) => {
    const cname = thisTemplate.categories.find(x => x.key === ckey).name
    let noValueArray = []

    const downLoad = (file) => {
        const fileData = fetch(file.url)
            .then(response => response.blob())
            .then(blob => {
                console.log('downloadblob',blob,file)
                fileDownload(blob,file.fileName)
            })
            .catch(e => console.error(e));
    }
    return (
        <Card className={"mt-2"} >
            <Card.Header><strong>{cname}</strong></Card.Header>
            <Table className={"show"} size="sm">
                <tbody>
                {thisTemplate.fields[ckey].fieldorder.map(fieldkey => {
                    console.log("cc",ckey,fieldkey)
                    const fieldinfo = thisTemplate.fields[ckey].fieldarray[fieldkey];
                    if (note["data"][ckey][fieldkey]) {
                        let value = note["data"][ckey][fieldkey]
                        if (fieldinfo.style ==="f") {
                            const file = value
                            if (value.fileName) value= <div><Button variant="outline-primary" onClick={() => downLoad(file)}>DownLoad</Button><span>{file.fileName}</span></div>
                             else value = ""
                        }
                        if (fieldinfo.style ==="i") {
                            if (value.fileName) value= <div><Image style={{maxHeight:"300px"}} src={value.url} fluid/></div>
                            else value = ""
                        }
                            if (Array.isArray(value)) value = value.join(", ")
                            if (value) {
                                return (
                                    <tr>
                                        <td>{fieldinfo.title}</td>
                                        <td>{value}</td>
                                    </tr>
                                )
                            } else {
                                noValueArray.push(fieldinfo.title)
                            }
                        } else {
                            noValueArray.push(fieldinfo.title)
                        }
                })
                }
                <tr style={{color:"grey", fontSize:".8em"}}><td className={"fit"}>*Empty Fields</td><td>{noValueArray.join(", ")}</td></tr>
                </tbody>
            </Table>
        </Card>
)
}
const ExtraNotes  =({note,thisTemplate})  => {
        console.log('en',note)
        return (
            <ul style={{color:"#444444", fontSize:".8em"}} className={"list-unstyled"}>
                {Object.keys(note["notes"]).map(ckey => {
                    const cname = thisTemplate.categories.find(x => x.key === ckey).name
                    return (
                        <div>
                        {   Object.entries(note["notes"][ckey]).map(n => {
                                return (<li >{cname}:{thisTemplate.fields[ckey].fieldarray[n[0]].title} - {n[1]}</li>)
                             })
                        }
                        </div>
                    )
                }
               )}
            </ul>
        )
}

const pdfCatTable = (ckey,note,thisTemplate,share) => {
    const cname = thisTemplate.categories.find(x => x.key === ckey).name
    let noValueArray = []
    let tableRows=[]
    thisTemplate.fields[ckey].fieldorder.forEach(fieldkey => {
        const fieldinfo = thisTemplate.fields[ckey].fieldarray[fieldkey]
        if (note["data"][ckey][fieldkey]) {
            let value = note["data"][ckey][fieldkey]
            if (Array.isArray(value)) value = value.join(", ")
            if (!(share && fieldinfo.private)) {
                if (value) {
                   if (fieldinfo.style === "i" || fieldinfo.style ==="f") {
                       value = "yes, see in app"
                   }

                   tableRows.push([fieldinfo.title, value])
                } else
                    noValueArray.push(fieldinfo.title)
            }
        }
    })
   tableRows.push([{text:"*Empty Fields",fontSize:9,color:"grey"},{text:noValueArray.join(", "),fontSize: 9,color:"grey"}]  )
    let object = [
        {text: cname, bold:true},
            {table: {
                headerRows: 1,
                widths: ["auto", "*"],
                body: tableRows
            },layout: {
                    hLineWidth: function(i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;},
                    vLineWidth: function(i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;},
                    hLineColor: function() {return  'lightgray';},
                    vLineColor: function() {return  'lightgray';},
                    paddingTop: function() { return 2; },
                    paddingBottom: function() { return 2; }
                }}
        ]
    return (object)
}

const pdfCategoryRow = (item,note,thisTemplate,share) => {
    if (Array.isArray(item)) {
        if (Array.isArray(item[0])) {
            return (
                {columns: [
                    {width: "50%",stack: [ pdfCatTable(item[0][0], note, thisTemplate,share),
                                         pdfCatTable(item[0][1], note, thisTemplate,share)]
                    },
                    {stack: pdfCatTable(item[1], note, thisTemplate,share)}
                ],columnGap:15}
                )
        }
        else {
            return (
                {columns: [
                        {width: "50%", stack:pdfCatTable(item[0], note, thisTemplate,share)},
                        {stack:pdfCatTable(item[1], note, thisTemplate,share)}
                    ],columnGap:15}
            )
        }
    } else
    {
        return (
            { stack: pdfCatTable( item, note, thisTemplate,share)}
        )
    }
}
const PdfExtraNotes = (note,thisTemplate) => {

    let noteArray = [" "]   //blank line to start
    Object.keys(note["notes"]).forEach(ckey => {
        const cname = thisTemplate.categories.find(x => x.key === ckey).name
        Object.entries(note["notes"][ckey]).forEach(n => {
            noteArray.push(cname + "-" + thisTemplate.fields[ckey].fieldarray[n[0]].title + ": " + n[1])
        })
    })

    return ({stack:[noteArray],fontSize:9,color:"#444444"})
}

export function emailPDF (notedata,templates,share,email) {
    if (notedata) {
            console.log("email nodedata", notedata)
            const thisTemplate = templates[notedata.type]
            const svg = thisTemplate.svg
            printPDF(svg, notedata, thisTemplate, share, 'email', email)

    }
}



const printPDF = async (svg,note,thisTemplate,share,action,email) => {
    console.log('please dont be here on rerender')
    let notecontent=[]
    let notedisplay =  thisTemplate.notedisplay
    notedisplay.forEach (item => {
        notecontent.push(pdfCategoryRow(item,note,thisTemplate,share))
            })
    notecontent.push(PdfExtraNotes(note,thisTemplate))
/*
    if (note.data.info.image.url) {
        let imageStuff ={
            image:'pdfimage',
            fit: [150,150]
        }
        notecontent.push (imageStuff)

    }
*/
    /* pdfMake.fonts = {
        Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf'
        }
    } */
    let docDefinition = {
        content: notecontent,
        header: {
            margin:[10,15,10,10],
            columns: [
                {svg: svg,width:16},
                {text:note.title,fontSize:13,margin:[4,0]},
                {text: [{text: 'Somm',color:'maroon'},'App'], alignment: 'right',fontSize:14}
            ]
        },
        footer: function(currentPage) {
            return {
                margin:[10,5,10,10],
                columns: [
                    {text: 'sommapp.com',fontSize:10},
                    {text: stringReverseDate(note.rtime),alignment:'center',fontSize:10},
                    {text: currentPage.toString(),fontSize:10,alignment:'right'}
                      ]
            };
        },
        defaultStyle: {
            font: 'Roboto',
            fontSize:10
        },

    }
/*
        if (note.data.info.image.url) {
            docDefinition['images'] = {pdfimage:note.data.info.image.url}
            }
        } */
        console.log('doc',docDefinition)


    const safeName = sanitize(note.title)
    const functions = getFsFunctions()
    switch(action) {

        case 'save':

            console.log('pdfsave',docDefinition)

            fetch('https://us-central1-sommapp2.cloudfunctions.net/generatePdf', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({doc:docDefinition,filename:safeName})

            }).then(response => response.blob())
                .then(blob =>{
                    console.log('pdf blob',blob)
                    fileDownload(blob,safeName+".pdf")
                })
                .catch(e => console.error(e))
            break;
        case   'print':
                // Need to figure this out with PDF running in functions now
            break;
        case 'email':

                const message = note.title+' note generated using <a href="https://sommapp.com"><span><span style="color:maroon;">Somm</span><span style="color:grey;">App </span></span></a><br><br>';
                const subject =note.title+' note is attached' ;
                const emailPdf = httpsCallable(functions, 'emailPdf');
                emailPdf ({email: email, subject: subject, message: message, doc:JSON.stringify(docDefinition),filename:safeName+".pdf"})
/*
                 fetch('https://us-central1-sommapp2.cloudfunctions.net/emailPdf', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({email: email, subject: subject, message: message, doc:docDefinition,filename:safeName+".pdf"}
                   )
                })*/
              break;

    }

}
