import React, {useEffect, useState, useMemo} from "react";
import {Row,Col,Dropdown,Accordion,Card,Modal,FormControl,Button,Form,InputGroup} from "react-bootstrap";
import {getReverseTime, returnSvg, stringReverseDate} from "./generalfunctions";
import {List, Trash, PencilSquare, People, FileText, Envelope, Funnel,FunnelFill,Grid3x3Gap} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import Select from "react-select";
import {ShowNote,emailPDF} from "./shownote";
import {FSqueryNotes,FSdeleteNote} from "./firestore";
import {addNoteToRoom} from "./tastingrooms";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {  } from 'firebase/functions';


export const ViewNotes = ({userInfo,templates,notetypes,joinRooms,compareObject,toggleCompareObject,viewState}) => {

    const [noteTable,setNoteTable,noteType,setNoteType,startDoc,setStartDoc,filters,setFilters] = viewState
    let noteoptions = useMemo(() =>{
        let notetable = [];
        notetypes.map((type) => {
        console.log('wasting time')
        notetable.push({"label":type.svg,"value":type.key})
        })
        return(notetable)
    },[notetypes])
    console.log('view',noteoptions)

   // const [startDoc,setStartDoc] = useState(null)
   // const [noteType,setnoteType] = useState("rw")
    //const [noteTable,setnoteTable] = useState ([])
   // const [filters,setFilters] = useState({})

    const [notekey,setNoteKey] = useState(null)
    const [noteData,setNoteData] = useState(null)
    const [roomOptions,setroomOptions] = useState ([])
    const [roomName,setroomName] = useState (null)
    const [modalType,setModalType] = useState(false)
    const [emailAddress,setEmailAddress] = useState ('')
    const [noteTitle,setNoteTitle] = useState('')

    const deletenoteTable = (key) => {
        let newnoteTable = noteTable.filter(note => note.key !== key)
        setNoteTable(newnoteTable)
    }

    useEffect(() => {
        if (userInfo.uid && noteTable.length == 0) {
            console.log('useeffect notesQuery',userInfo)
            notesQuery("type", {"key": "type", "op": null})
        }
    },[userInfo.uid],noteTable)
    useEffect(()=>{
        if (userInfo.uid) {
            let roomArr = []
            joinRooms.forEach((room) => {
                console.log(room)
                roomArr.push({label: room.roomName, value: room.roomName})
            })
            setroomOptions(roomArr)
        }
    },[joinRooms,userInfo.uid])
    console.log('at enter',noteTable,filters)

    const  notesQuery = (type,filteradd) => {
        console.log("nQ",type,filteradd)
        if (type==="keyword" && filteradd.value && filteradd.value.length >2) {
            //sort from local table
        }
        else {

            if (filteradd.op === null){
                delete filters[filteradd.key]
                setFilters(filters)
            }
            else {
                filters[filteradd.key] = filteradd
                console.log('filters before',filters)
                setFilters(filters)
                console.log('filters after',filters)
            }

           FSqueryNotes(filters,"users/"+userInfo.uid+"/notes",5,null)
                .then(obj => {
                    console.log("return query",obj, obj.noteArray)
                    setNoteTable(obj.noteArray)
                    setStartDoc(obj.lastDoc)
                })
        }
    }
    const  moreNotesQuery = () => {
            FSqueryNotes(filters,"users/"+userInfo.uid+"/notes",5,startDoc)
                .then(obj => {
                    console.log("return query",obj, obj.noteArray)
                    setNoteTable(noteTable.concat(obj.noteArray))
                    setStartDoc(obj.lastDoc)
                })
        }



    const notetypeFilter = (selectvalue) => {
        console.log(selectvalue)
        if (selectvalue) {
            setNoteType(selectvalue)
            notesQuery("type", {"key":"type","op":"==","value":selectvalue})
        }
        else
            notesQuery ("type",{"key":"type","op":null})
    }
    const multipleFilter = (selectvalue) => {
        console.log(selectvalue)
        if (selectvalue) {
            setNoteType(selectvalue)
            notesQuery("type", {"key":"type","op":"==","value":selectvalue})
        }
        else
            notesQuery ("type",{"key":"type","op":null})
    }
    const dateFilter = (selectvalue) => {
        console.log(selectvalue)
        if (selectvalue) {
            let timelimit = getReverseTime( Date.now() - 2592000000 * selectvalue.mo )
            notesQuery("date", {"key":"rtime","op":selectvalue.op,"value":timelimit})
        }
        else notesQuery("date",{"key":"rtime","op":null})

    }
    const onChangeEmailAddress = (e) => {
        e.persist();
        console.log('email',e)
        setEmailAddress(e.target.value);
    }
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        console.log('handlesubmit',event)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log('bad email')

        } else {
            emailPDF(noteData,templates,true,(emailAddress === '') ? userInfo.info.email : emailAddress)
            console.log('sendemail',emailAddress)
            setModalType(false)
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!'),
        );

        return (
            <span
                type="button"
                onClick={decoratedOnClick}
            >
                {children}
            </span>
        );
    }

    return (
        <div>
            <Accordion className={"filters"}>
                        <Row>
                            <Col className={"filtercol"} xs={1}>
                                <CustomToggle eventKey="0" >
                                <span>&nbsp; </span>
                                    {Object.keys(filters).length > 0 ? (
                                        <FunnelFill size={24} color={'maroon'}/>
                                    ) : (
                                        <Funnel size={24} color={'maroon'}/>
                                        )}
                                </CustomToggle>
                            </Col>
                            <Col className={"filtercol"}  xs={2}>
                                <SingleSelect title={'Note Type'} selectoptions={noteoptions} updatefunction={notetypeFilter} initialSelect={noteType}/>
                                {/*<SingleSelect title={'Date Range'} selectoptions={dateoptions} updatefunction={dateFilter}/>*/}
                            </Col>
                            <Col className={"filtercol"}  sm={1} xs={1}>&nbsp;</Col>
                            <Col className={"filtercol"}  xs={3}>
                                <FormControl
                                    placeholder="Title Key Word"
                                    aria-label="Title Search"
                                    onChange = {(ev) => {
                                        if (ev.target.value.length >1) {notesQuery("keyword",{"key":"kw","op":"array-contains","value":ev.target.value})}
                                        else { notesQuery ("keyword",{"key":"kw","op":null})}
                                    }}
                                />
                            </Col>
                            <Col className={"filtercol"}  xs={2}>
                            <CustomToggle eventKey="0" >
                            <Funnel size={16}  /> More
                            </CustomToggle>
                        </Col>
                    </Row>
                      <Accordion.Collapse eventKey="0">
                        <Card className={"filtercard"}>
                            <Row>
                                {/* <Col className={"filtercol"}  sm={2}>
                                    <SingleSelect title={'Note Type'} selectoptions={noteoptions} updatefunction={notetypeFilter}/>
                                </Col>*/}
                                <Col className={"filtercol"}  sm={8}>
                                    <Row>
                                        <Col className={"filtercol"} >
                                            <SingleSelect  title={"Type Details"} selectoptions={templates[noteType].searchSelects.multiple.options}/>
                                        </Col>
                                        <Col className={"filtercol"} >
                                            <SingleSelect  title={"Info Details"} selectoptions={noteoptions}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className={"filtercol"}>
                                    <Row>
                                        <Col className={"filtercol"}>
                                            <Button variant="outline-primary" size='md'>Search</Button>
                                        </Col>
                                        <Col className={"filtercol"}>
                                            <Button variant="outline-secondary" size='md'>Reset</Button>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Card>
                    </Accordion.Collapse>

            </Accordion>
        <div>
            <BuildNoteTable noteTable={noteTable} templates={templates} setNoteKey={setNoteKey} setNoteData={setNoteData} setModalType={setModalType} compareObject={compareObject} toggleCompareObject={toggleCompareObject} setNoteTitle={setNoteTitle}/>
        </div>
            {(startDoc) ? <div><span onClick={()=>moreNotesQuery()}>More</span></div> : ""}

        <Modal show={modalType==="show"} id={"shownotemodal"} onHide={()=>setModalType(false)} dialogClassName={"shownote-modal"} size={"xl"}>
            <Modal.Body>
                <ShowNote noteData={noteData} templates={templates}  />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={()=>setModalType(false)}>Close</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={modalType==="delete"} id={"deletemodal"} onHide={()=>setModalType(false)} >
            <Modal.Body>
                Are you sure you want to delete?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={()=>setModalType(false)}>Cancel</Button>
                <Button variant="outline-primary" onClick={()=>{FSdeleteNote(notekey,"users/"+userInfo.uid+"/notes/",userInfo.uid); deletenoteTable(notekey); setModalType(false)}}>Yes</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={modalType==="email"} id={"mailmodal"} onHide={()=>setModalType(false)} >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Body>

             <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" name="emailAddress"  placeholder="Enter email address or leave blank to send to yourself" value={emailAddress} onChange={(e)=>onChangeEmailAddress(e)} />
                <Form.Control.Feedback  type="invalid">
                    Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={()=>setModalType(false)}>Cancel</Button>
                <Button variant="outline-primary" type="submit" /*onClick={()=>{/*emailPDF(notekey,templates,true,(emailAddress === '') ? emailAddress: userInfo.info.email);console.log('sendemail',emailAddress); setModalType(false)}}*/>Yes</Button>
            </Modal.Footer>
            </Form>
        </Modal>

        <Modal show={modalType==="select"} id={"joinmodal"} onHide={()=>setModalType(false)} >
            <Modal.Body>
                <Form.Label>Add Note to Which Tasting Room</Form.Label>
                <SingleSelect title={"Tasting Rooms"} selectoptions={roomOptions} updatefunction={setroomName}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={()=>setModalType(false)}>Cancel</Button>
                <Button variant="outline-primary" onClick={() =>{addNoteToRoom(userInfo,notekey, roomName, noteTitle,false) ;setModalType(false)}}>Add</Button>
                <Button variant="outline-primary" onClick={() =>{addNoteToRoom(userInfo,notekey, roomName, noteTitle,true) ;setModalType(false)}}>Add Blind</Button>
            </Modal.Footer>
        </Modal>
    </div>
    )
}
const BuildNoteTable = React.memo(({noteTable,templates,setNoteKey,setNoteData,setModalType,compareObject,toggleCompareObject,setNoteTitle}) => {
    console.log('notetable',noteTable,templates,setNoteKey,setModalType,compareObject,toggleCompareObject)
    return (
        <div>
            {noteTable.map((note,index) => (
        <Row className="note_row " key={index}>
            <Col xs={10} sm={9} md={8} onClick={()=> {setNoteKey(note.key); setModalType("show")}} className="note-title ">{returnSvg(templates[note.type].svg)} {note.title}</Col>
            <Col  xs={2} md={2} className="note-date  d-none d-sm-block">{stringReverseDate(note.rtime)}</Col>
            <Col xs={1} sm={1}>
                <Dropdown key={index} drop={'right'} >
                    <Dropdown.Toggle variant="light"  as={CustomToggle} id="dropdown-basic">
                        abc
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{minWidth:'3rem'}}>
                        <Dropdown.Item key="1" onClick={()=> {setNoteData(note); setModalType("show")}} ><FileText size={24}/></Dropdown.Item>
                        <Dropdown.Item key="2" as={Link} to={'/editnote/'+note.type+'/'+note.key}><PencilSquare size={24}/></Dropdown.Item>
                        <Dropdown.Item key="3" onClick={()=> {setNoteData(note); setModalType("email")}}><Envelope size={24}/></Dropdown.Item>
                        <Dropdown.Item key="4" onClick={()=> {toggleCompareObject(note)}}><Grid3x3Gap size={24}/></Dropdown.Item>
                        <Dropdown.Item key="6" onClick={()=> {setNoteKey(note.key);setNoteTitle(note.title); setModalType("select")}}><People size={24}/></Dropdown.Item>
                        <Dropdown.Item key="5" onClick={()=> {setNoteKey(note.key); setModalType("delete")}}><Trash size={24}/></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={1}>
                {(note.key in compareObject) ? <span onClick={()=>{toggleCompareObject(note)}}><Grid3x3Gap size={16} /></span> : null}
            </Col>

        </Row>
    ))}
        </div>)
            })





const SingleSelect = React.memo(({title,selectoptions,updatefunction,initialSelect}) => {
    console.log(initialSelect)
    const [selectedOption,updateSelect] =useState(initialSelect)
    console.log("SingleSelect",title,selectoptions)
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#999',
            'fontSize':'.9rem'
        }),
    }
    console.log('option',selectedOption)
    return (
        <Select
            isClearable
            isSearchable={false}
            placeholder ={title}
            customStyles = {customStyles}
            value = {selectoptions.find(obj => obj.value === selectedOption)}
            options = {selectoptions}
            onChange={(valobj) => {updateSelect(valobj ? valobj.value : null);console.log('select',valobj); updatefunction(valobj ? valobj.value : null)}}
            theme={theme => ({
                 ...theme,
                 colors: {
                     ...theme.colors,
                     primary: 'rgba(128,0,0,1)',
                     primary25:'rgba(128,0,0,.25)',
                     primaty50:'rgba(128,0,0,.50)',
                     primary75:'rgba(128,0,0,.75)',
                 },
            })}
        />)
})

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        style = {{"color":"maroon"}}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <List size={24} />
    </a>
));

const makeNoteTableEntry = (dbobj) => {
    let tentry = {data:{info:{title:{}}}}
    tentry.key = dbobj.key
    tentry.type = dbobj.type
    tentry.rtime = dbobj.rtime
    tentry.title = dbobj.title
    tentry.kw = dbobj.kw
    return tentry
}
const getOptionArray = (templates,notetype,category,field) => {
    let fieldinfo = templates[notetype].fields[category].fieldarray[field];
    let selectoptions = [];
    if (fieldinfo.options) {
        let optarr = templates[fieldinfo.options[0]].options[fieldinfo.options[1]].optArr;
        optarr.forEach((option) => {
            let o = [];
            option.options.forEach((suboption, index) => {
                o.push({'value': suboption, 'label': suboption})
            })
            selectoptions.push({'label': option.label, 'options': o})
        })
    }
    return(selectoptions)
}

const dateoptions = [
    {"label":"< 3 months","value":{"op":">","mo":3}},  //note reverse timestamps so < is less than 3 months
    {"label":"< 6 months","value":{"op":">","mo":6}},
    {"label":"< 1 year","value":{"op":">","mo":12}},
    {"label":"< 2 years","value":{"op":">","mo":24}},
    {"label":"> 1 year","value":{"op":"<","mo":12}},
]
/*save for later
class Element extends React.Component {
    onChange = (e) => {
        clearTimeout(this.setStateTimeout)
        this.setStateTimeout = setTimeout(()=> {
            this.setState({inputValue: e.target.value})
        }, 500)
    }
}
 */
