export function getTemplates() {
return {

    "rw": {  //red template
        "type": "temp",
        "title": "Red Wine",
        "key": "rw",
        "pagetype": "red",
        "defaultTab": "info",
        "notedisplay": [[["s", "n"], "p"], ["i", "f"], "info"],
        "searchSelects": {
            "multiple": {
                "title": "Choose any of:",
                "options": [
                    {"cat": "f", "field": "var", "skey":"f-var", "op": "array-contains", "label": "Varietal"},
                    {"cat": "f", "field": "vin", "skey":"f-vin", "op": "==", "label": "Vintage"},
                    {"cat": "f", "field": "reg", "skey":"f-reg", "op": "==", "label": "Region"},
                    {"cat": "f", "field": "co", "skey":"f-co", "op": "==", "label": "Country"},
                    {"cat": "info", "field": "tt", "skey":"inf-tt", "op": "==", "label": "Tasting Type"},
                    {"cat": "info", "field": "loc", "skey":"inf-loc", "op": "==", "label": "Location"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": "==", "label": "Vendor is:"},
                    {"cat": "info", "field": "con", "skey":"inf-con", "op": "==", "label": "Contact is:"},
                    {"cat": "info", "field": "food", "skey":"inf-fo", "op": "==", "label": "Food Pairing is:"},
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": "==", "label": "Rating is:"},
                    ]
                },
                "title": {
                    "label": "Choose ONE of:",
                    "options": [
                        {"cat": "info", "field": "rat", "skey":"inf-rat", "op": ["<=", ">="], "label": "Rating between:"},
                        {"cat": "info", "field": "cost", "skey":"inf-cos", "op": ["<=", ">="], "label": "Cost between"},
                        {"cat": "info", "field": "pr", "skey":"inf-pr", "op": ["<=", ">="], "label": "Price between"},
                        {"cat": "info", "field": "ven", "skey":"inf-ven", "op": ">=", "label": "Vendor starts with:"}
                    ]
                }
            },

        "tkey": [{"cat":"info","f":"title"}, {"cat":"f","f":"var"},{"cat":"f","f":"vin"},{"cat":"f","f":"reg"}, {"cat":"f","f":"co"}],
        "categorynames":{"s":"Sight","n":"Nose","p":"Palate","i":"Initial","f":"Final","info":"Info"},
        "categories": [{"key":"s","name":"Sight"}, {"key":"n","name":"Nose"}, {"key":"p","name":"Palate"}, {"key":"i","name":"Initial"}, {"key":"f","name":"Final"}, {"key":"info","name":"Info"}],
        "fields": {
            "s": {  //sight
                "fieldcount":9,
                "fieldorder": ["clar", "con", "col", "scol", "rim", "st", "tear", "gas", "cu"],
                "fieldarray": {
                    "clar": {"title": "Clarity", "style": "sr","check":"y", "skey":null, "options": ["wi", "clarity"]},
                    "con": {"title": "Concentration", "style": "sr","check":"y", "skey":null, "options": ["wi", "concentration"]},
                    "col": {"title": "Color", "style": "sm", "skey":null, "options": ["rw", "rcolor"]},
                    "scol": {"title": "Secondary Colors", "style": "sm", "skey":null, "options": ["rw", "rscol"]},
                    "rim": {"title": "Rim Variation", "style": "sb", "skey":null, "options": ["wi", "rim"]},
                    "st": {"title": "Extract/Staining", "style": "sr","skey":null, "options": ["rw", "stain"]},
                    "tear": {"title": "Tearing", "style": "sr", "skey":null, "options": ["wi", "tearing"]},
                    "gas": {"title": "Gas Evidence", "style": "sb", "skey":null, "options": ["wi", "gas"]},
                    "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["rw", "curws"]}
                }
            },
            "n": {   //nose
                "fieldcount":10,
                "fieldorder": ["cf", "int", "age", "fr", "frc", "nfr", "ea", "min", "wood", "cu"],
                "fieldarray": {
                    "cf": {"title": "Clean/Faulty", "style": "sm", "skey":null, "options": ["wi", "clfl"]},
                    "int": {"title": "Intensity", "style": "sr", "skey":null, "options": ["wi", "intensity"]},
                    "age": {"title": "Age Assessment", "style":"sr", "skey":null, "options": ["wi", "ageassess"]},
                    "fr": {"title": "Fruit", "style": "sm", "skey":null, "options": ["rw", "rfruit"]},
                    "frc": {"title": "Fruit Character", "style": "sm", "skey":null, "options": ["wi", "frchar"]},
                    "nfr": {"title": "Non Fruit", "style": "sm", "skey":null, "options": ["wi", "nfruit"]},
                    "ea": {"title": "Earth", "style": "sm", "skey":null, "options": ["wi", "earth"]},
                    "min": {"title": "Mineral", "style": "sm", "skey":null, "options": ["wi", "mineral"]},
                    "wood": {"title": "Wood", "style": "sm", "skey":null, "options": ["wi", "wood"]},
                    "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["rw", "curwn"]}
                }
            },
            "p": {   //palate
                "fieldcount":16,
                "fieldorder": ["sw", "fr", "frc", "nfr", "ea", "min", "wood", "tan", "acid", "alc", "body", "tex", "bal", "lf", "com", "cu"],
                "fieldarray": {
                    "sw": {"title": "Sweetness", "style": "sr", "skey":null, "options": ["wi", "sweetness"]},
                    "fr": {"title": "Fruit", "style": "sm", "skey":null, "options": ["rw", "rfruit"]},
                    "frc": {"title": "Fruit Character", "style": "sm", "skey":null, "options": ["wi", "frchar"]},
                    "nfr": {"title": "Non-Fruit", "style": "sm", "skey":null, "options": ["wi", "nfruit"]},
                    "ea": {"title": "Earth", "style": "sm", "skey":null, "options": ["wi", "earth"]},
                    "min": {"title": "Mineral", "style": "sm", "skey":null, "options": ["wi", "mineral"]},
                    "wood": {"title": "Wood", "style": "sm", "skey":null, "options": ["wi", "wood"]},
                    "tan": {"title": "Tannin", "style": "sr", "skey":null, "options": ["rw", "tannin"]},
                    "acid": {"title": "Acid", "style": "sr", "skey":null, "options": ["wi", "acid"]},
                    "alc": {"title": "Alcohol", "style": "sr", "skey":null, "options": ["wi", "alcohol"]},
                    "body": {"title": "Body", "style": "sb", "skey":null, "options": ["wi", "body"]},
                    "tex": {"title": "Texture", "style": "sb", "skey":null, "options": ["wi", "texture"]},
                    "bal": {"title": "Balance", "style": "sb", "skey":null, "options": ["wi", "balance"]},
                    "lf": {"title": "Length/Finish", "style": "sr", "skey":null, "options": ["wi", "lenfin"]},
                    "com": {"title": "Complexity", "style": "sr", "skey":null, "options": ["wi", "complex"]},
                    "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["rw", "curwp"]}
                }
            },
            "i": {   //initial
                "fieldcount":6,
                "fieldorder": ["var", "wo", "cli", "co", "age", "cu"],
                "fieldarray": {
                    "var": {"title": "Varietals", "style": "sm", "skey":null, "options": ["wi", "redvar"]},
                    "wo": {"title": "Old/New World", "style": "sb", "skey":null, "options": ["wi", "world"]},
                    "cli": {"title": "Climate", "style": "sr", "skey":null, "options": ["wi", "climate"]},
                    "co": {"title": "Possible Countries", "style": "sm", "skey":null, "options": ["wi", "country"]},
                    "age": {"title": "Age Range", "style": "sr", "skey":null, "options": ["wi", "age"]},
                    "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["rw", "curwi"]}
                }
            },
            "f": {   //final
                "fieldcount":6,
                "fieldorder": ["var", "co", "reg", "qu", "vin", "cu"],
                "fieldarray": {
                    "var": {"title": "Varietal", "style": "sm", "skey":"f-var", "options": ["wi", "redvar"]},
                    "co": {"title": "Country", "style": "ss", "skey":"f-co", "options": ["wi", "country"]},
                    "reg": {"title": "Region", "style": "tl", "skey":"f-reg", "file": "general"},
                    "qu": {"title": "Quality", "style": "sm", "skey":null, "options": ["wi", "quality"]},
                    "vin": {"title": "Vintage", "style": "ss", "skey":"f-vin", "options": ["wi", "vin"]},
                    "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["rw", "curwf"]}
                }
            },
            "info": {
                "fieldorder": ["title","notes","pnotes","tt","rat","food","ven","cost","pr","con","loc","file","image"],
                "fieldarray": {
                    "title": {"title": "Title", "style": "t", "skey":null, "placeholder": "Info from FINAL will be added."},
                    "notes": {"title": "General Notes", "style": "ta", "skey":null},
                    "pnotes": {
                        "title": "Private Notes",
                        "style": "ta",
                        "skey":null,
                        "private": true,
                        "placeholder": "These notes are not shared."
                        },
                    "tt": {"title": "Tasting Type", "style": "sb", "skey":"inf-tt", "options": ["wi", "tastetype"]},
                    "rat": {"title": "Rating", "style": "sr", "skey":"inf-rat", "options": ["wi", "rating"]},
                    "food": {"title": "Food Pairing", "style": "t", "skey":"inf-fo"},
                    "ven": {"title": "Vendor/Producer", "style": "tl", "skey":"inf-ven", "file": "general"},
                    "cost": {"title": "Cost", "style": "tn", "skey":"inf-cos", "private": true, "placeholder": "Not Shared"},
                    "pr": {"title": "Price", "style": "tn", "skey":"inf-pr"},
                    "con": {
                        "title": "Contact",
                        "style": "tl",
                        "skey":"inf-con",
                        "file": "general",
                        "private": true,
                        "placeholder": "Not Shared"
                        },
                    "loc": {"title": "Tasting Location", "style": "t", "skey":"inf-loc"},
                    "file": { "title": "File", "private":true, "style":"f", "skey":null},
                    "image": {"title": "Photo","private":true, "style": "i", "skey":null}
                }
            },
        },


        "options": {
            "rcolor": {
                "title": "Color",
                "used": "Red-Sight",
                "optArr": [{"label":" ","options": ["Purple", "Ruby", "Red", "Garnet"]}]
            },
            "rscol": {
                "title": "Secondary Colors",
                "used": "Red-Sight",
                "optArr": [{"label":" ","options": ["Orange", "Blue", "Ruby", "Garnet", "Brown"]}]
            },
            "stain": {
                "title": "Extract/Staining",
                "used": "Red-Sight",
                "optArr": [{"label":" ","options": ["None", "Faint", "Light", "Medium", "Heavy"]}]
            },
            "rfruit": {
                "title": "Fruit", "used": "Red-Sight/Palate", "optArr": [
                    {"label":"Basic","options": ["Red Fruit", "Black Fruit", "Blue Fruit"]},
                    {"label":"Berries","options": ["Raspberry", "Blueberry", "Blackberry", "Strawberry", "Boysenberry", "Huckleberry", "Red Currents", "Black Currents", "Mulberry", "Strawberry Jam", "Cranberry", "A\u00e7ai berry"]},
                    {"label":"Stone Fruit","options": ["Plums", "Prunes", "Red Cherry", "Black Cherry", "Maraschino Cherry", "Sour Cherry", "Morellino Cherry", "Sour Plum", "Sweet Cherry", "Peach", "Black Plum", "Red Plum", "Blue Plum"]},
                    {"label":"Other","options": ["Fig", "Raisin", "Rhubarb", "Tomato", "Watermelon", "Dates"]}
                ]
            },
            "tannin": {
                "title": "Tannin",
                "used": "Red-Palate",
                "optArr": [{"label":" ","options": ["Low", "Medium-", "Medium", "Medium+", "High"]}]
            },
            "curws": {"title": " ", "used": "Red-Sight", "optArr": [{"label":"Other","options": []}]},
            "curwn": {"title": " ", "used": "Red-Nose", "optArr": [{"label":"Other","options": []}]},
            "curwp": {"title": " ", "used": "Red-Palate", "optArr": [{"label":"Other","options": []}]},
            "curwi": {"title": " ", "used": "Red-Initial", "optArr": [{"label":"Other","options": []}]},
            "curwf": {"title": " ", "used": "Red-Final", "optArr": [{"label":"Other","options": []}]}
        },
        "svg":'<svg width="20" height="20"  viewBox="0 0 295.82 295.82" xmlns="http://www.w3.org/2000/svg"> <g > <path d="m 207.944,165.311 c 15.661,-15.661 22.621,-40.023 20.882,-62.644 L 220.125,8.701 C 220.125,3.481 216.645,0 211.424,0 H 84.396 c -5.22,0 -8.701,3.48 -8.701,8.701 l -8.701,93.966 c -1.74,22.621 5.22,45.243 20.882,62.644 13.642,15.156 32.61,24.57 52.204,26.868 v 86.24 h -36.543 c -5.22,0 -8.701,3.48 -8.701,8.701 0,5.221 3.48,8.701 8.701,8.701 h 92.226 c 3.48,0 8.701,-3.48 8.701,-8.701 0,-5.221 -3.48,-8.701 -8.701,-8.701 H 157.48 v -85.875 c 19.246,-2.627 36.839,-13.608 50.464,-27.233 z m -3.48,-147.91 7.657,76.565 H 86.136 c -0.261,0 -0.452,0.105 -0.713,0.122 L 93.097,17.401 Z M 84.396,111.054 c 0.592,0.105 1.096,0.313 1.74,0.313 h 127.029 c 0,16.357 -4.994,31.305 -15.661,43.503 -12.181,13.921 -29.582,20.882 -48.723,20.882 -19.141,0 -36.543,-6.96 -48.723,-20.882 C 89.407,142.69 84.396,126.576 84.396,111.054 Z" /> <path  style="fill:#800000;stroke-width:0.36611387" d="m 140.99045,175.18658 c -21.67554,-2.2515 -39.83447,-14.41043 -49.047284,-32.8413 -4.347819,-8.69811 -6.538049,-17.10897 -7.312468,-28.08114 l -0.206723,-2.92891 64.253925,0.0936 64.25393,0.0936 v 2.20908 c 0,3.19756 -0.66804,8.79295 -1.52689,12.78912 -1.1763,5.47319 -2.67389,9.67551 -5.41762,15.20213 -7.16603,14.43434 -19.43694,25.09994 -34.77972,30.22974 -9.40253,3.14369 -20.08295,4.28683 -30.21715,3.23416 z" /> </g></svg>'
    },
    "ww": {  //white template
        "type":"temp",
        "title": "White Wine",
        "key": "ww",
        "pagetype": "white",
        "defaultTab": "info",
        "notedisplay": [[["s", "n"], "p"], ["i", "f"], "info"],
        "searchSelects": {
            "multiple": {
                "title": "Choose any of:",
                "options": [
                    {"cat": "f", "field": "var", "skey":"f-var", "op": "array-contains", "label": "Varietal"},
                    {"cat": "f", "field": "vin", "skey":"f-vin", "op": "==", "label": "Vintage"},
                    {"cat": "f", "field": "reg", "skey":"f-reg", "op": "==", "label": "Region"},
                    {"cat": "f", "field": "co", "skey":"f-co", "op": "==", "label": "Country"},
                    {"cat": "info", "field": "tt", "skey":"inf-tt", "op": "==", "label": "Tasting Type"},
                    {"cat": "info", "field": "loc", "skey":"inf-loc", "op": "==", "label": "Location"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": "==", "label": "Vendor is:"},
                    {"cat": "info", "field": "con", "skey":"inf-con", "op": "==", "label": "Contact is:"},
                    {"cat": "info", "field": "food", "skey":"inf-fo", "op": "==", "label": "Food Pairing is:"},
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": "==", "label": "Rating is:"},
                ]
            },
            "title": {
                "label": "Choose ONE of:",
                "options": [
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": ["<=", ">="], "label": "Rating between:"},
                    {"cat": "info", "field": "cost", "skey":"inf-cos", "op": ["<=", ">="], "label": "Cost between"},
                    {"cat": "info", "field": "pr", "skey":"inf-pr", "op": ["<=", ">="], "label": "Price between"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": ">=", "label": "Vendor starts with:"}
                ]
            }
        },
        "tkey": [{"cat":"info","f":"title"}, {"cat":"f","f":"var"},{"cat":"f","f":"vin"},{"cat":"f","f":"reg"}, {"cat":"f","f":"co"}],
        "categorynames":{"s":"Sight","n":"Nose","p":"Palate","i":"Initial","f":"Final","info":"Info"},
        "categories": [{"key":"s","name":"Sight"}, {"key":"n","name":"Nose"}, {"key":"p","name":"Palate"}, {"key":"i","name":"Initial"}, {"key":"f","name":"Final"}, {"key":"info","name":"Info"}],
        "fields":
            {
                "s": {  //sight
                    "fieldcount":8,
                    "fieldorder": ["clar", "con", "col", "scol", "rim", "tear", "gas", "cu"],
                    "fieldarray": {
                        "clar": {"title": "Clarity", "style": "sr", "skey":null, "options": ["wi", "clarity"]},
                        "con": {"title": "Concentration", "style": "sr", "skey":null, "options": ["wi", "concentration"]},
                        "col": {"title": "Color", "style": "sm", "skey":null, "options": ["ww", "rcolor"]},
                        "scol": {"title": "Secondary Colors", "style": "sm", "skey":null, "options": ["ww", "rscol"]},
                        "rim": {"title": "Rim Variation", "style": "sb", "skey":null, "options": ["wi", "rim"]},
                        "tear": {"title": "Tearing", "style": "sr", "skey":null, "options": ["wi", "tearing"]},
                        "gas": {"title": "Gas Evidence", "style": "sb", "skey":null, "options": ["wi", "gas"]},
                        "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["ww", "curws"]}
                    }
                },
                "n": {   //nose
                    "fieldcount":10,
                    "fieldorder": ["cf", "int", "age", "fr", "frc", "nfr", "ea", "min", "wood", "cu"],
                    "fieldarray": {
                        "cf": {"title": "Clean/Faulty", "style": "sm", "skey":null, "options": ["wi", "clfl"]},
                        "int": {"title": "Intensity", "style": "sr", "skey":null, "options": ["wi", "intensity"]},
                        "age": {"title": "Age Assessment", "style": "sr", "skey":null, "options": ["wi", "ageassess"]},
                        "fr": {"title": "Fruit", "style": "sm", "skey":null, "options": ["ww", "rfruit"]},
                        "frc": {"title": "Fruit Character", "style": "sm", "skey":null, "options": ["wi", "frchar"]},
                        "nfr": {"title": "Non Fruit", "style": "sm", "skey":null, "options": ["wi", "nfruit"]},
                        "ea": {"title": "Earth", "style": "sm", "skey":null, "options": ["wi", "earth"]},
                        "min": {"title": "Mineral", "style": "sm", "skey":null, "options": ["wi", "mineral"]},
                        "wood": {"title": "Wood", "style": "sm", "skey":null, "options": ["wi", "wood"]},
                        "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["ww", "curwn"]}
                    }
                },
                "p": {   //palate
                    "fieldcount":16,
                    "fieldorder": ["sw", "fr", "frc", "nfr", "ea", "min", "wood", "phe", "acid", "alc", "body", "tex", "bal", "lf", "com", "cu"],
                    "fieldarray": {
                        "sw": {"title": "Sweetness", "style": "sr", "skey":null, "options": ["wi", "sweetness"]},
                        "fr": {"title": "Fruit", "style": "sm", "skey":null, "options": ["ww", "rfruit"]},
                        "frc": {"title": "Fruit Character", "style": "sm", "skey":null, "options": ["wi", "frchar"]},
                        "nfr": {"title": "Non-Fruit", "style": "sm", "skey":null, "options": ["wi", "nfruit"]},
                        "ea": {"title": "Earth", "style": "sm", "skey":null, "options": ["wi", "earth"]},
                        "min": {"title": "Mineral", "style": "sm", "skey":null, "options": ["wi", "mineral"]},
                        "wood": {"title": "Wood", "style": "sm", "skey":null, "options": ["wi", "wood"]},
                        "phe": {"title": "Phenolic/Bitter", "style": "sb", "skey":null, "options": ["ww", "phenolic"]},
                        "acid": {"title": "Acid", "style": "sr", "skey":null, "options": ["wi", "acid"]},
                        "alc": {"title": "Alcohol", "style": "sr", "skey":null, "options": ["wi", "alcohol"]},
                        "body": {"title": "Body", "style": "sb", "skey":null, "options": ["wi", "body"]},
                        "tex": {"title": "Texture", "style": "sb", "skey":null, "options": ["wi", "texture"]},
                        "bal": {"title": "Balance", "style": "sb", "skey":null, "options": ["wi", "balance"]},
                        "lf": {"title": "Length/Finish", "style": "sr", "skey":null, "options": ["wi", "lenfin"]},
                        "com": {"title": "Complexity", "style": "sr", "skey":null, "options": ["wi", "complex"]},
                        "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["ww", "curwp"]}
                    }
                },
                "i": {   //initial
                    "fieldcount":6,
                    "fieldorder": ["var", "wo", "cli", "co", "age", "cu"],
                    "fieldarray": {
                        "var": {"title": "Varietals", "style": "sm", "skey":null, "options": ["wi", "whitevar"]},
                        "wo": {"title": "Old/New World", "style": "sb", "skey":null, "options": ["wi", "world"]},
                        "cli": {"title": "Climate", "style": "sr", "skey":null, "options": ["wi", "climate"]},
                        "co": {"title": "Possible Countries", "style": "sm", "skey":null, "options": ["wi", "country"]},
                        "age": {"title": "Age Range", "style": "sr", "skey":null, "options": ["wi", "age"]},
                        "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["ww", "curwi"]}
                    }
                },
                "f": {   //final
                    "fieldcount":6,
                    "fieldorder": ["var", "co", "reg", "qu", "vin", "cu"],
                    "fieldarray": {
                        "var": {"title": "Varietal", "style": "sm", "skey":"f-var", "options": ["wi", "whitevar"]},
                        "co": {"title": "Country", "style": "ss", "skey":"f-co", "options": ["wi", "country"]},
                        "reg": {"title": "Region", "style": "tl", "skey":"f-reg", "file": "general"},
                        "qu": {"title": "Quality", "style": "sm", "skey":null, "options": ["wi", "quality"]},
                        "vin": {"title": "Vintage", "style": "ss", "skey":"f-vin", "options": ["wi", "vin"]},
                        "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["ww", "curwf"]}
                    }
                },
                "info": {
                    "fieldorder": ["title","notes","pnotes","tt","rat","food","ven","cost","pr","con","loc","file","image"],
                    "fieldarray": {
                        "title": {"title": "Title", "style": "t", "skey":null, "placeholder": "Info from FINAL will be added."},
                        "notes": {"title": "General Notes", "style": "ta", "skey":null},
                        "pnotes": {
                            "title": "Private Notes",
                            "style": "ta",
                            "skey":null,
                            "private": true,
                            "placeholder": "These notes are not shared."
                        },
                        "tt": {"title": "Tasting Type", "style": "sb", "skey":"inf-tt", "options": ["wi", "tastetype"]},
                        "rat": {"title": "Rating", "style": "sr", "skey":"inf-rat", "options": ["wi", "rating"]},
                        "food": {"title": "Food Pairing", "style": "t", "skey":"inf-fo"},
                        "ven": {"title": "Vendor/Producer", "style": "tl", "skey":"inf-ven", "file": "general"},
                        "cost": {"title": "Cost", "style": "tn", "private": true, "placeholder": "Not Shared"},
                        "pr": {"title": "Price", "style": "tn", "skey":"inf-pr"},
                        "con": {
                            "title": "Contact",
                            "style": "tl",
                            "skey":"inf-con",
                            "file": "general",
                            "private": true,
                            "placeholder": "Not Shared"
                        },
                        "loc": {"title": "Tasting Location", "style": "t"},
                        "file": { "title": "File", "private":true, "style":"f", "skey":null},
                        "image": {"title": "Photo", "private":true, "skey":null, "style": "i"}
                    }
                }
            },
        "options": {
            "rcolor": {
                "title": "Color",
                "used": "White-Sight",
                "optArr": [{"label":" ","options": ["Winter White","Straw","Yellow","Gold"]}]
            },
            "rscol": {
                "title": "Secondary Colors",
                "used": "White-Sight",
                "optArr": [{"label":" ","options":["Silver", "Green", "Copper"]}]
            },
            "rfruit": {
                "title": "Fruit", "used": "White-Sight/Palate", "optArr": [
                    {"label":" ","options": ["Citrus","Apple/Pear","Stone/Pit","Tropical","Melon"]}
                ]
            },
            "phenolic": {
                "title": "Phenolic/Bitter",
                "used": "White-Palate",
                "optArr": [{"label":" ","options": ["No", "Yes"]}]
            },
            "curws": {"title": "Other", "used": "White-Sight", "optArr": [{"label":" ","options": []}]},
            "curwn": {"title": "Other", "used": "White-Nose", "optArr": [{"label":" ","options": []}]},
            "curwp": {"title": "Other", "used": "White-Palate", "optArr": [{"label":" ","options": []}]},
            "curwi": {"title": "Other", "used": "White-Initial", "optArr": [{"label":" ","options": []}]},
            "curwf": {"title": "Other", "used": "White-Final", "optArr": [{"label":" ","options": []}]}
        },
        "svg":'<svg width="20" height="20" viewBox="0 0 295.82 295.82" xmlns="http://www.w3.org/2000/svg" > <g> <path d="m 207.944,165.311 c 15.661,-15.661 22.621,-40.023 20.882,-62.644 L 220.125,8.701 C 220.125,3.481 216.645,0 211.424,0 H 84.396 c -5.22,0 -8.701,3.48 -8.701,8.701 l -8.701,93.966 c -1.74,22.621 5.22,45.243 20.882,62.644 13.642,15.156 32.61,24.57 52.204,26.868 v 86.24 h -36.543 c -5.22,0 -8.701,3.48 -8.701,8.701 0,5.221 3.48,8.701 8.701,8.701 h 92.226 c 3.48,0 8.701,-3.48 8.701,-8.701 0,-5.221 -3.48,-8.701 -8.701,-8.701 H 157.48 v -85.875 c 19.246,-2.627 36.839,-13.608 50.464,-27.233 z m -3.48,-147.91 7.657,76.565 H 86.136 c -0.261,0 -0.452,0.105 -0.713,0.122 L 93.097,17.401 Z M 84.396,111.054 c 0.592,0.105 1.096,0.313 1.74,0.313 h 127.029 c 0,16.357 -4.994,31.305 -15.661,43.503 -12.181,13.921 -29.582,20.882 -48.723,20.882 -19.141,0 -36.543,-6.96 -48.723,-20.882 C 89.407,142.69 84.396,126.576 84.396,111.054 Z"/> <path style="fill:#ffe680;stroke-width:0.36611387" d="m 140.99045,175.18658 c -21.67554,-2.2515 -39.83447,-14.41043 -49.047284,-32.8413 -4.347819,-8.69811 -6.538049,-17.10897 -7.312468,-28.08114 l -0.206723,-2.92891 64.253925,0.0936 64.25393,0.0936 v 2.20908 c 0,3.19756 -0.66804,8.79295 -1.52689,12.78912 -1.1763,5.47319 -2.67389,9.67551 -5.41762,15.20213 -7.16603,14.43434 -19.43694,25.09994 -34.77972,30.22974 -9.40253,3.14369 -20.08295,4.28683 -30.21715,3.23416 z"/></g></svg>'
    },
    "g": {  //general template
        "type": "temp",
        "title": "General",
        "key": "g",
        "pagetype": "gen",
        "library": "general",
        "defaultTab": "info",
        "notedisplay": ["info"],
        "searchArray": [],
        "searchSelects": {
            "multiple": {
                "title": "Choose any of:",
                "options": [
                    {"cat": "info", "field": "loc", "skey":"inf-loc", "op": "==", "label": "Location"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": "==", "label": "Vendor is:"},
                    {"cat": "info", "field": "con", "skey":"inf-con", "op": "==", "label": "Contact is:"},
                ]
            },
            "title": {
                "label": "Choose ONE of:",
                "options": [
                    {"cat": "info", "field": "cost", "skey":"inf-cos", "op": ["<=", ">="], "label": "Cost between"},
                    {"cat": "info", "field": "pr", "skey":"inf-pr", "op": ["<=", ">="], "label": "Price between"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": ">=", "label": "Vendor starts with:"}
                ]
            }
        },
        "tkey": [{"cat":"info","f":"title"}],
        "categorynames":{"info":"Info"},
        "categories": [{"key":"info","name":"Info"}],
        "fields":
            {"info": {
                    "fieldorder": ["title","notes","pnotes","ven","cost","pr","con","loc","file","image"],
                    "fieldarray": {
                        "title": {"title": "Title", "skey":null, "style": "t"},
                        "notes": {"title": "General Notes", "skey":null, "style": "ta"},
                        "pnotes": {
                            "title": "Private Notes",
                            "style": "ta",
                            "skey":null,
                            "private": true,
                            "placeholder": "These notes are not shared."
                        },
                        "ven": {"title": "Vendor/Producer", "style": "t", "skey":"inf-ven"},
                        "cost": {"title": "Cost", "style": "tn", "skey":"inf-co",  "private": true, "placeholder": "Not Shared"},
                        "pr": {"title": "Price", "style": "tn", "skey":"inf-pr"},
                        "con": {"title": "Contact", "style": "t", "skey":"inf-con",  "private": true, "placeholder": "Not Shared"},
                        "loc": {"title": "Note Location", "style": "t", "skey":"inf-loc"},
                        "file": { "title": "File", "private":true, "style":"f", "skey":null},
                        "image": {"title": "Photo", "private":true, "skey":null, "style": "i"}
                    }
                }
            },
        "options": {},
        "svg":'<svg width="20"  height="20"  viewBox="0 0 375.76239 449.26733" xmlns="http://www.w3.org/2000/svg" ><path d="M 355.66619,127.36634 H 114.51203 c -11.09812,0 -20.096188,8.99876 -20.096188,20.11881 v 281.66337 c 0,11.12067 8.998068,20.11881 20.096188,20.11881 h 180.86562 l 80.38473,-80.47525 V 147.48515 c 0,-11.12005 -8.98803,-20.11881 -20.09619,-20.11881 z m -60.28854,293.45174 v -52.026 h 51.96746 z m 60.28854,-72.14481 h -60.28854 c -11.10816,0 -20.09618,8.99877 -20.09618,20.11881 v 60.35644 H 114.51203 V 147.48515 H 355.66619 Z M 134.6082,197.78218 c 0,-5.56034 4.49401,-10.0594 10.04809,-10.0594 h 180.86564 c 5.55408,0 10.04809,4.49906 10.04809,10.0594 0,5.56034 -4.49402,10.0594 -10.04809,10.0594 H 144.65629 c -5.55408,0 -10.04809,-4.49906 -10.04809,-10.0594 z m 0,60.35643 c 0,-5.56033 4.49401,-10.0594 10.04809,-10.0594 h 180.86564 c 5.55408,0 10.04809,4.49907 10.04809,10.0594 0,5.56035 -4.49402,10.05941 -10.04809,10.05941 H 144.65629 c -5.55408,0 -10.04809,-4.49906 -10.04809,-10.05941 z m 0,60.35644 c 0,-5.56033 4.49401,-10.05941 10.04809,-10.05941 h 180.86564 c 5.55408,0 10.04809,4.49908 10.04809,10.05941 0,5.56034 -4.49402,10.05941 -10.04809,10.05941 H 144.65629 c -5.55408,0 -10.04809,-4.49907 -10.04809,-10.05941 z" style="fill:#800000;stroke-width:0.6283592"/></svg>'
        },
    "b": {   //beer
        "type": "temp",
        "title": "Beer",
        "key": "b",
        "pagetype": "gen",
        "library": "general",
        "defaultTab": "info",
        "notedisplay": ["info"],
        "searchArray": [],
        "searchSelects": {
            "multiple": {
                "title": "Choose any of:",
                "options": [
                    {"cat": "info", "field": "loc", "skey":"inf-loc", "op": "==", "label": "Location"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": "==", "label": "Vendor is:"},
                    {"cat": "info", "field": "con", "skey":"inf-con", "op": "==", "label": "Contact is:"},
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": "==", "label": "Rating is:"},
                ]
            },
            "title": {
                "label": "Choose ONE of:",
                "options": [
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": ["<=", ">="], "label": "Rating between:"},
                    {"cat": "info", "field": "cost", "skey":"inf-cos", "op": ["<=", ">="], "label": "Cost between"},
                    {"cat": "info", "field": "pr", "skey":"inf-pr", "op": ["<=", ">="], "label": "Price between"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": ">=", "label": "Vendor starts with:"}
                ]
            }
        },
        "tkey": [{"cat":"info","f":"title"}],
        "categorynames":{"info":"Info"},
        "categories": [{"key":"info","name":"Info"}],
        "fields":
            {"info": {
                    "fieldorder": ["title","notes","pnotes","rat","ven","cost","pr","con","loc","file","image"],
                    "fieldarray": {
                        "title": {"title": "Title", "skey":null, "style": "t"},
                        "notes": {"title": "General Notes", "skey":null, "style": "ta"},
                        "pnotes": {
                            "title": "Private Notes",
                            "style": "ta",
                            "skey":null,
                            "private": true,
                            "placeholder": "These notes are not shared."
                        },
                        "rat": {"title": "Rating", "style": "sr", "skey":"inf-rat", "options": ["b", "brating"]},
                        "ven": {"title": "Vendor/Producer", "style": "t", "skey":"inf-ven"},
                        "cost": {"title": "Cost", "style": "tn", "skey":"inf-co",  "private": true, "placeholder": "Not Shared"},
                        "pr": {"title": "Price", "style": "tn", "skey":"inf-pr", },
                        "con": {"title": "Contact", "style": "t", "skey":"inf-con",  "private": true, "placeholder": "Not Shared"},
                        "loc": {"title": "Note Location", "style": "t", "skey":"inf-loc"},
                        "file": { "title": "File", "private":true, "style":"f", "skey":null},
                        "image": {"title": "Photo","private":true, "skey":null, "style": "i"}
                    }
                }
            },
        "options": {
            "brating": {
                "title": "Rating",
                "used": "Beer-Info",
                "optArr": [{"label":" ","options": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}]
            }
        },
        "svg":'<svg height="20" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m 361.9514,15.350577 c -3.397,-3.681 -16.81446,-1.807221 -16.81446,-1.807221 l -170.60457,0.263203 c -5.00899,0.0077 -15.92625,-0.866124 -19.32225,2.814876 -3.396,3.681 -5.101,10.203062 -4.699,15.195062 L 177.975,399.419 c 2.393,29.769 -3.303,59.686 -16.471,86.491 -2.732,5.561 -2.402,6.83227 0.874,12.09227 3.276,5.26 9.033,6.32742 15.23,6.32742 h 150.64975 c 6.197,0 14.83601,0.0253 18.11301,-5.23467 3.277,-5.26 0.35277,-15.14624 -2.38023,-20.70924 C 336.24625,448.32654 331.634,429.243 334.028,399.474 L 359.869,77.94 c 0.12,-0.829 0.201,-1.67 0.203,-2.532 l 4.67212,-38.484 0.0449,-15.118264 c 0.007,-2.250498 -2.83762,-6.455159 -2.83762,-6.455159 z M 307.738,476.112 H 204.261 c 8.382,-25.121 10.11294,-52.543 9.485,-79.432 L 189.369,93.308 H 322.63 L 298.254,396.68 c -2.162,26.889 1.102,54.311 9.484,79.432 z M 339.50768,67.896232 H 175.56046 L 172.4995,35.888 342.56962,34.353938 Z" /></g><path style="fill:#d4aa00;stroke-width:2.16949153" d="m 185.87628,482.41133 c 7.87318,-12.13987 11.95849,-40.48406 11.99102,-57.8111 0.0207,-11.03702 -0.72963,-90.32308 -7.29234,-171.57045 -6.56271,-81.24736 -13.46627,-165.973109 -13.46627,-167.654809 0,-2.562575 22.17496,-1.523575 76.72182,-1.523575 54.54686,0 80.87468,-2.759175 80.87468,-0.1966 0,1.6817 -5.24076,91.191134 -11.74609,172.443104 -1.96052,24.48707 -8.84713,155.21873 -8.82641,166.25575 0.0325,17.32704 6.11799,53.99852 6.11799,53.99852 2.82794,5.89608 -17.84207,7.10305 -65.78474,7.10305 -47.94267,0 -68.58966,-1.04389 -68.58966,-1.04389 z"/></svg>'
    },
    "s": {   //spirits
        "type": "temp",
        "title": "Spirit",
        "key": "s",
        "pagetype": "gen",
        "library": "general",
        "defaultTab": "info",
        "notedisplay": ["f","info"],
        "searchSelects": {
            "multiple": {
                "title": "Choose any of:",
                "options": [
                    {"cat": "f", "field": "stype", "skey":"f-stype", "op": "array-contains", "label": "Spirit Type"},
                    {"cat": "f", "field": "sreg", "skey":"f-reg", "op": "==", "label": "Region"},
                    {"cat": "f", "field": "sco", "skey":"f-sco", "op": "==", "label": "Country"},
                    {"cat": "info", "field": "loc", "skey":"inf-loc", "op": "==", "label": "Location"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": "==", "label": "Vendor is:"},
                    {"cat": "info", "field": "con", "skey":"inf-con", "op": "==", "label": "Contact is:"},
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": "==", "label": "Rating is:"},
                ]
            },
            "title": {
                "label": "Choose ONE of:",
                "options": [
                    {"cat": "info", "field": "rat", "skey":"inf-rat", "op": ["<=", ">="], "label": "Rating between:"},
                    {"cat": "info", "field": "cost", "skey":"inf-cos", "op": ["<=", ">="], "label": "Cost between"},
                    {"cat": "info", "field": "pr", "skey":"inf-pr", "op": ["<=", ">="], "label": "Price between"},
                    {"cat": "info", "field": "ven", "skey":"inf-ven", "op": ">=", "label": "Vendor starts with:"}
                ]
            }
        },
        "tkey": [{"cat":"info","f":"title"},{"cat":"f","f":"stype"},{"cat":"f","f":"sreg"},{"cat":"f","f":"sco"}],
        "categorynames":{"i":"Initial","f":"Final","info":"Info"},
        "categories": [{"key":"i","name":"Initial"}, {"key":"f","name":"Final"},{"key":"info","name":"Info"}],
        "search": ["info|loc|s", "info|cost|sn", "info|pr|sn", "info|ven|s", "info|con|s",  "info|title|sp","f|stype|s", "f|sreg|s", "f|sco|s"],
        "fields":
            {
            "f": {   //final
                "fieldorder":["stype","sco","sreg","cu"],
                "fieldarray": {
                    "stype": {"title": "Type", "style": "ss","skey":"f-stype", "options": ["s", "type"]},
                    "sco": {"title": "Country", "style": "ss", "skey":"f-sco", "options": ["s", "country"]},
                    "sreg": {"title": "Region", "style": "tl", "skey":"f-sreg", "file": "general"},
                    "cu": {"title": "Other", "style": "sm", "skey":null, "options": ["s", "cusf"]}
                }
            },
            "info": {
                "fieldorder": ["title","notes","pnotes","rat","ven","cost","pr","con","loc","file","image"],
                "fieldarray": {
                    "title": {"title": "Title", "skey":null, "style": "t"},
                    "notes": {"title": "General Notes", "skey":null, "style": "ta"},
                    "pnotes": {
                        "title": "Private Notes",
                        "style": "ta",
                        "skey":null,
                        "private": true,
                        "placeholder": "These notes are not shared."
                    },
                    "rat": {"title": "Rating", "style": "sr", "skey":"inf-rat", "options": ["s", "srating"]},
                    "ven": {"title": "Vendor/Producer", "style": "t", "skey":"inf-ven"},
                    "cost": {"title": "Cost", "style": "tn", "skey":"inf-co", "private": true, "placeholder": "Not Shared"},
                    "pr": {"title": "Price", "style": "tn", "skey":"inf-pr", },
                    "con": {"title": "Contact", "style": "t", "skey":"inf-con",  "private": true, "placeholder": "Not Shared"},
                    "loc": {"title": "Note Location", "style": "t", "skey":"inf-loc", },
                    "file": { "title": "File", "private":true, "style":"f", "skey":null},
                    "image": {"title": "Photo", "private":true, "skey":null, "style": "i"}
                }
            }
        },
        "options": {
            "srating": {
                "title": "Rating",
                "used": "Spirit-Info",
                "optArr": [{"label":" ","options": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}]
            },
            "type": {
                "title": "Type", "used": "Spirit-Initial/Final",
                "optArr": [
                    {"label":"Whiskey","options": ["Whiskey", "Scotch", "Bourban", "Tennessee Whiskey", "Irish Whiskey", "Canadian Whisky", "Rye Whiskey", "Blended Whiskey"]},
                    {"label":"Tequila","options": ["Blanco Tequila", "Reposado Tequila", "Anejo Tequila","Gold Tequila"]},
                    {"label":"Rum","options": ["Light Rum", "Gold Rum", "Dark Rum", "Spiced Rum"]},
                    {"label":"Vodka","options": ["Vodka", "Flavored Vodka"]},
                    {"label":"Gin","options": ["London Dry Gin", "Plymouth Gin", "American Gin","Genever"]},
                    {"label":"Brandy","options": ["Cognac", "Armagnac", "Spanish Brandy", "Pisco", "American Brandy", "Grappa", ]},
                    {"label":"Other","options": ["Cocktails"]}
                ]
            },
            "country": {
                "title": "Country", "used": "Spirit-Initial/Final",
                "optArr": [
                    {"label":"Old World","options": ["Scotland", "Ireland", "France", "Spain", "Italy", "Germany", "Austria", "Greece", "Portugal", "Russia"]},
                    {"label":"New World","options": ["USA", "Mexico", "Chile", "Argentina", "Canada", "New Zealand", "Australia", "South Africa"]}
                ]
            },
            "cusi": {"title": "Other", "used": "Spirit-Initial", "optArr": [{"label":" ","options": []}]},
            "cusf": {"title": "Other", "used": "Spirit-Final", "optArr": [{"label":" ","options": []}]}
        },
        "svg":'<svg width="20" height="20" viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg"><path d="M428.501,26.016c3.84-4.8,4.608-11.392,1.92-16.928C427.733,3.52,422.133,0,415.989,0h-384    c-6.144,0-11.744,3.52-14.4,9.056c-2.688,5.536-1.952,12.128,1.92,16.928L207.989,261.6V416h-64c-8.832,0-16,7.168-16,16    c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16c0-8.832-7.168-16-16-16h-64V261.6L428.501,26.016z M65.269,32h317.44    l-25.6,32H90.869L65.269,32z M223.989,230.4L116.469,96h215.04L223.989,230.4z"/><path style="fill:#dde9af;stroke-width:1.89830506" d="M 170.07602,161.54576 117.66136,96.054237 H 224 330.33865 L 277.92398,161.54576 C 249.09592,197.5661 224.83013,227.03729 224,227.03729 c -0.83013,0 -25.09592,-29.47119 -53.92398,-65.49153 z" /></svg>'
    },
    "wi": {  //common options
        "type":"options",
        "title": "Wine",
        "key": "wi",
        "options": {
            "clarity": {
                "title": "Clarity",
                "used": "Red/White-Sight",
                "optArr": [{"label":" ","options": ["Clear", "Medium Hazy", "Hazy", "Medium Turbid", "Turbid"]}]
            },
            "concentration": {
                "title": "Concentration",
                "used": "Red/White-Sight",
                "optArr": [{"label":" ","options": ["Pale", "Medium", "Deep"]}]
            },
            "rim": {"title": "Rim Variation", "used": "Red/White-Sight", "optArr": [{"label":" ","options": ["No", "Yes"]}]},
            "tearing": {"title": "Tearing", "used": "Red/White-Sight", "optArr": [{"label":" ","options": ["Light", "Medium", "Heavy"]}]},
            "gas": {"title": "Gas Evidence", "used": "Red/White-Sight", "optArr": [{"label":" ","options": ["No", "Yes"]}]},
            "clfl": {
                "title": "Clean/Faulty",
                "used": "Red/White-Nose",
                "optArr": [{"label":" ","options": ["TCA", "H2S", "Volatile Acidity", "Ethyl Acetate", "Brettanomyces", "Oxidization"]}]
            },
            "intensity": {
                "title": "Intensity",
                "used": "Red/White-Nose",
                "optArr": [{"label":" ","options": ["Delicate", "Moderate", "Powerful"]}]
            },
            "ageassess": {
                "title": "Age Assessment",
                "used": "Red/White-Nose",
                "optArr": [{"label":" ","options": ["Youthful", "Developing", "Vinous"]}]
            },
            "frchar": {
                "title": "Fruit Character",
                "used": "Red/White-Nose/Palate",
                "optArr": [{"label":" ","options": ["Ripe", "Fresh", "Tart", "Baked", "Stewed", "Dried", "Desiccated", "Bruised", "Jammy"]}]
            },
            "nfruit": {
                "title": "Non-Fruit",
                "used": "Red/White-Nose/Palate",
                "optArr": [{"label":" ","options": ["Floral", "Vegetal", "Herbal", "Spice", "Petrol", "Animal", "Barn", "Fermentation"]}]
            },
            "earth": {
                "title": "Earth",
                "used": "Red/White-Nose/Palate",
                "optArr": [{"label":" ","options": ["Forest Floor", "Compost", "Mushrooms", "Potting Soil"]}]
            },
            "mineral": {
                "title": "Mineral",
                "used": "Red/White-Nose/Palate",
                "optArr": [{"label":" ","options": ["Mineral", "Wet Stone", "Limestone", "Chalk", "Slate", "Flint"]}]
            },
            "wood": {
                "title": "Wood",
                "used": "Red/White-Nose/Palate",
                "optArr": [{"label":" ","options": ["None", "Old", "New", "Large", "Small", "French", "American"]}]
            },
            "sweetness": {
                "title": "Sweetness",
                "used": "Red/White-Palate",
                "optArr": [{"label":" ","options": ["Bone Dry", "Dry", "Off-Dry", "Medium Sweet", "Sweet", "Lusciously Sweet"]}]
            },
            "acid": {
                "title": "Acid",
                "used": "Red/White-Palate",
                "optArr": [{"label":" ","options": ["Low", "Medium-", "Medium", "Medium+", "High"]}]
            },
            "alcohol": {
                "title": "Alcohol",
                "used": "Red/White-Palate",
                "optArr": [{"label":" ","options": ["Low", "Medium-", "Medium", "Medium+", "High"]}]
            },
            "body": {"title": "Body", "used": "Red/White-Palate", "optArr": [{"label":" ","options": ["Light", "Medium", "Full"]}]},
            "texture": {"title": "Texture", "used": "Red/White-Palate", "optArr": [{"label":" ","options": ["Creamy", "Round", "Lean"]}]},
            "balance": {"title": "Balance", "used": "Red/White-Palate", "optArr": [{"label":" ","options": ["No", "Yes"]}]},
            "lenfin": {
                "title": "Length/Finish",
                "used": "Red/White-Palate",
                "optArr": [{"label":" ","options": ["Short", "Medium-", "Medium", "Medium+", "Long"]}]
            },
            "complex": {
                "title": "Complexity",
                "used": "Red/White-Palate",
                "optArr": [{"label":" ","options": ["Low", "Medium-", "Medium", "Medium+", "High"]}]
            },
            "country": {
                "title": "Country", "used": "Red/White-Initial/Final",
                "optArr": [
                    {"label":"Old World","options": ["France", "Spain", "Italy", "Germany", "Austria", "Greece", "Portugal"]},
                    {"label":"New World","options": ["USA", "Chile", "Argentina", "Mexico", "Canada", "New Zealand", "Australia", "South Africa"]}
                ]
            },
            "redvar": {
                "title": "Varietal",
                "used": "Red-Initial/Final",
                "optArr": [{"label":" ","options": ["Syrah", "Nebbiolo", "Shiraz", "Pinot Noir", "Cabernet Sauvignon", "Zinfandel", "Cabernet Blend", "Merlot Blend", "Grenache", "Grenache Blend", "Carmenere", "Cabernet Franc", "Tempranillo", "Gamay", "Sangiovese", "Malbec", "GSM", "Bordeaux Blend"]}]
            },
            "whitevar": {
                "title": "Varietal",
                "used": "White-Initial/Final",
                "optArr": [{"label":" ","options": ["Chardonnay","Sauvignon Blanc","Semillon","Moscato","Pinot Grigio","Pinot Gris","Gewürztraminer","Riesling","Viognier","Chenin Blanc"]}]
            },
            "world": {"title": "World", "used": "Red/White-Initial", "optArr": [{"label":" ","options": ["Old World", "New World"]}]},
            "climate": {
                "title": "Climate",
                "used": "Red/White-Initial",
                "optArr": [{"label":" ","options": ["Cool", "Moderate", "Warm"]}]
            },
            "age": {"title": "Age", "used": "Red/White-Initial", "optArr": [{"label":" ","options": ["1-3", "3-5", "5-10", "10+"]}]},
            "quality": {
                "title": "Quality",
                "used": "Red/White-Final",
                "optArr": [{"label":" ","options": ["Grand", "Premier Cru", "Reserva", "Gran Reserva"]}]
            },
            "vin": {
                "title": "Vintage",
                "used": "Red/White-Final",
                "optArr": [{"label":" ","options": ["2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "10y", "20y", "30y", "NV"]}]
            },
            "tastetype": {"title": "Tasting Type", "used": "Red/White-Info", "optArr": [{"label":" ","options": ["Known", "Blind"]}]},
            "rating": {
                "title": "Rating",
                "used": "Red/White-Info",
                "optArr": [{"label":" ","options": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}]
            }
        }
    }

}}
