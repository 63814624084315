import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, InputGroup, Row,Card} from "react-bootstrap";
import {getUserInfo, SignInScreen, getAuthRef} from "./App";
import {writeUserInfo,FSaddNote} from "./firestore";
import {wines}  from "./fakewines"
import validator from 'validator'
import {sendPasswordResetEmail,updateEmail,sendEmailVerification} from "firebase/auth"
import { PayPalButton } from "react-paypal-button-v2";

const states = [
    'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
    'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
    'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
    'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
    'VT','VI','VA','WA','WV','WI','WY'
]


export const Account = ({user,userInfo,setUserInfo,isSignedIn}) => {

    const [nickName,setnickName] = useState(userInfo.info.nickName)
    const [state,setstate] = useState(userInfo.info.state)
    const [email,setEmail] = useState(user.email)
    const [newAccount,setNewAccount] = useState("Guest")
    const [emailError, setEmailError] = useState('')

    const validateEmail = (email) => {
        if (validator.isEmail(email)) {
            setEmailError('')
        } else {
            setEmailError('Enter valid Email!')
        }
    }
    const updateUserInfo=()=>{
        userInfo.info.state= state
        userInfo.info.nickName = nickName
        writeUserInfo( userInfo,setUserInfo)
    }
   // userInfo.info.state = state
   // userInfo.info.nickName = nickName
    useEffect( ()=>{
        /*wines.forEach(note =>
        {
            FSaddNote(note)
        })*/
    },[])
    useEffect(()=>{
        setnickName(userInfo.info.nickName)
        setstate(userInfo.info.state)
        setEmail(user.email)
    },[userInfo,user])

    const resetPassword =()=> {
        const auth = getAuthRef();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert('Reset link has been sent to your email address')
            })
            .catch((error) => {
                console.log("email error",error)
            });
    }

    const resetEmail=()=>{
        const auth = getAuthRef();
        updateEmail(auth.currentUser, email).then(() => {
            sendEmailVerification(auth.currentUser)
                .then(() => {
                alert ('Email sent for verification')
                });
        }).catch((error) => {
              console.log("set email error",error)
        });
    }
if(!userInfo.uid) {

    return(
        <div>

            {/*{<SignInScreen isSignedIn={isSignedIn}/>}*/}
        </div>
    )}
    else {

        console.log("account display",userInfo)
        return(
            <div>
                <Card key={"infocard"}><Card.Body>
                <span>Account Type: {userInfo.info.account.type} {(userInfo.info.account.expireDate) ? "Expires "+userInfo.info.account.expireDate : ""}</span>
                <Form.Group  controlId={"form-nick"} key={"nick"}>
                    <Form.Label column sm={3} md={2}>
                        Nick Name
                    </Form.Label>
                        <Form.Control key={"nickName"} value={nickName} type="text" onChange={(ev) => setnickName(ev.target.value)}/>
                </Form.Group>
                <Form.Group controlId="state" key={"state"}>
                    <Form.Label>Select State</Form.Label>
                    <Form.Control size="sm" as="select" value={state} onChange={(ev=>{setstate(ev.target.value)})}>
                        {states.map(state => {
                                return (<option>{state}</option>)
                            })}
                     </Form.Control>
                </Form.Group>
                <Button variant="outline-primary" id="displayName" onClick={()=>updateUserInfo()}>Save</Button>
                </Card.Body></Card>
                <Card key={"emailcard"}><Card.Body>
                <Form.Group  controlId={"form-email"} key={"email"}>
                    <Form.Label column sm={3} md={2}>
                        Email
                    </Form.Label>
                    <Form.Control value={email} type="email" onChange={(ev) => {validateEmail(ev.target.value); setEmail(ev.target.value)}}/>
                    {emailError ? <Form.Text>{emailError}</Form.Text> :
                    <Form.Text>Changing Email Will Send Verication Email &nbsp; <Button variant="outline-primary" onClick={()=>resetEmail()}>Update Email</Button></Form.Text>}
                </Form.Group>
                <Button variant="outline-primary" id="resetPassword" onClick={()=>resetPassword()}>Password Reset Email</Button>
                </Card.Body></Card>
                <Card key={"paymentcard"}><Card.Body>
                    <Form.Group controlId="Payment_Plan">
                        <Form.Label>Account Type Subscription</Form.Label>
                        <Form.Control as="select" >
                            <option key={"1y"}>General User 1 year $25</option>
                            <option key={"6m"}>General User Subscription $15 every 6 months</option>
                            <option key={"t1y"}>Texas General User 1 year $27</option>
                            <option key={"t6m"}>Texas General User Subscription $16 every 6 months </option>
                        </Form.Control>
                    </Form.Group>
                <PayPalButton
                    options={{
                        clientId: "AcDRAVWJ44EFNze-CCHKF3eSVvUkY0_Dhle2YhbqbOenLieok_JYuymFPiD0vIs5nSrLZWbNcNEBlu4p",
                        vault:true,
                        intent:"subscription"
                    }}
                    createSubscription={(data, actions) => {
                        return actions.subscription.create({plan_id: 'P-568440789S567512MMBFLXLA'});
                    }}
                    onApprove={(data, actions) => {
                        // Capture the funds from the transaction
                        return actions.subscription.get().then(function(details) {
                            // Show a success message to your buyer
                            console.log('PayPal',details,data,actions)
                            alert("Subscription completed");

                            // OPTIONAL: Call your server to save the subscription
                            /*return fetch("/paypal-subscription-complete", {
                                method: "post",
                                body: JSON.stringify({
                                    orderID: data.orderID,
                                    subscriptionID: data.subscriptionID
                                })
                            });*/
                        });

                    }}
                />
                <div>Thanks for Signing UP</div>
                </Card.Body></Card>
            </div>
        )
}



}

