import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Dropdown,
    Tooltip,
    Accordion,
    Card,
    Modal,
    ModalTri,
    FormControl,
    Button,
    Table, Form
} from "react-bootstrap";
import {getReverseTime, returnSvg, stringReverseDate} from "./generalfunctions";
import {List,Grid3x3Gap,PlusSquareFill } from 'react-bootstrap-icons';
import fileDownload from "js-file-download";


export const CompareNotes = ({templates,compareObject,toggleCompareObject,userInfo}) => {
    const [notekeys,setnotekeys] = useState(Object.keys(compareObject))

    const togglenotekey = (key) => {
        let copy = [...notekeys];
        if (copy.includes(key)) {

            let index = copy.indexOf(key);
            if (index !== -1) {
                copy.splice(index, 1);
            }
            setnotekeys(copy)
        }
        else {
            copy.push(key)
            setnotekeys(copy)
        }
    }



    let compareTable = []
    console.log("comparestart",compareObject,templates)

    if (Object.keys(compareObject).length) {
        return (
            <div>
            {Object.entries(compareObject).map((notearray,index) => (
                <Row className="note_row " key={index}>
                    <Col xs={10} sm={9} md={8} key={1}
                         className="note-title ">{returnSvg(templates[notearray[1].type].svg)} {notearray[1].title}</Col>
                    <Col xs={2} md={2} key={2}className="note-date  d-none d-sm-block">{stringReverseDate(notearray[1].rtime)}</Col>
                    <Col xs={1} key={3} >
                        {<span ><PlusSquareFill size={20} onClick={()=>togglenotekey(notearray[0])} color={notekeys.includes(notearray[0]) ? "maroon" : "darkgrey"}/></span> }
                    </Col>
                    <Col xs={1} key={4}>
                        {<span ><Grid3x3Gap size={20} onClick={()=>{toggleCompareObject(notearray[1]); togglenotekey(notearray[0])}} color={"darkgrey"}/></span> }
                    </Col>
                </Row>
            ))}
            <hr/>
                <h5 className="text-center">Compare</h5>
                <ShowCompare notekeys={notekeys} templates={templates} compareObject={compareObject} userInfo={userInfo} />
             </div>
        )
    }
    else {
        return (
            <div>
               Please select some notes to compare using  <Grid3x3Gap size={24}/> from the dropdown <List size={24}/> on View Notes Page

            </div>

        )
    }
}

function ShowCompare({notekeys, templates,compareObject,userInfo}) {

    const [noteArray,setnoteArray] = useState ([])
    const [noteDataArray,setnoteDataArray] = useState([])
     const [showEmailNote,setEmailNote] = useState(false)
    const [emailAddress,setEmailAddress] = useState ('')
     const handleEmailNoteClose = () => setEmailNote(false);
     const handleEmailNoteOpen = () => {
         console.log('hs')
         setEmailNote(true);
     }
    console.log('entercompare',notekeys,templates)
    useEffect( () => {
        console.log('startload')
        if (notekeys.length) {
            let notedata=[]
            notekeys.forEach((notekey) => {
                notedata.push(compareObject[notekey])
            })
            setnoteDataArray(buildDataArray(notedata,templates))
            setnoteArray(notedata)
            console.log('new')
        }
    },[notekeys])

    const onChangeEmailAddress = (e) => {
        e.persist();
        console.log('email',e)
        setEmailAddress(e.target.value);
    }
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        console.log('handlesubmit',event)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log('bad email')

        } else {
            printPDF(compareObject,noteArray,templates,"email",emailAddress); handleEmailNoteClose()
            setEmailNote(false)
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    if (noteArray.length) return (
        <Container fluid>
            <Row key={"buttons"}>
                <Button variant="outline-primary" size="sm" id="print" onClick={()=>printPDF(compareObject,noteArray,templates,"save","")}>Save PDF</Button>
                <span> &nbsp; </span>
                <Button variant="outline-primary" size="sm" id="print" onClick={()=>handleEmailNoteOpen()}>Email PDF</Button>
            </Row>
            {noteArray.map((note,index) =>

            <Row className={index} key={index}>
                <span>Note {index+1}: </span> <span> {returnSvg(templates[note.type].svg)} {note.title} <span style={{fontSize:".8em"}}> {stringReverseDate(note.rtime)}</span></span>
            </Row>
            )}
           <Table responsive="md" className={"show"} size="sm">
                <thead>
                <tr key={"header"}>
                    <th style={{width:"20%"}}></th>
                    {noteArray.map((x,index) => <th>Note: {index+1}</th> )}
                </tr>
                </thead>
                <tbody>
               {noteDataArray}
               </tbody>
           </Table>
            <Modal show={showEmailNote} id={"mailmodal"} onHide={()=>handleEmailNoteClose()} >
                <Modal.Header></Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>

                        <Form.Group controlId="emailAddress">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" name="emailAddress"  placeholder="Enter email address or leave blank to send to yourself" value={emailAddress} onChange={(e)=>onChangeEmailAddress(e)} />
                            <Form.Control.Feedback  type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={()=>setEmailNote(false)}>Cancel</Button>
                        <Button variant="outline-primary" type="submit" /*onClick={()=>{/*emailPDF(notekey,templates,true,(emailAddress === '') ? emailAddress: userInfo.info.email);console.log('sendemail',emailAddress); setModalType(false)}}*/>Yes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>


    )
    else return <div>Loading</div>
}
const buildDataArray =(notedata,templates) => {
    let dataObject = {}   //build sparse object to start
    notedata.forEach((note,index) => {
        Object.keys(note.data).map(cat =>{
            dataObject[cat] = dataObject[cat] || {0:<strong>{templates[note.type].categorynames[cat]}</strong>}
            Object.keys(note.data[cat]).map(field => {
                if(Array.isArray(note.data[cat][field])) {              //only compare select fields which are all arrrays even if single
                    dataObject[field] = dataObject[field] || {0: [templates[note.type].fields[cat].fieldarray[field].title]}
                    dataObject[field][index + 1] = note.data[cat][field].toString()
                }
            })
        })

    })
    let dataArray = [] //fill out array with with html rows
    Object.entries(dataObject).map(rowentry =>{
        let row=[]
        for (let i=0; i < notedata.length+1; i++) {
           row.push(<td>{rowentry[1][i]}</td>)
        }
        dataArray.push(<tr key={rowentry[0]}>{row}</tr>)
    })
    return(dataArray)
}

const pdfBuildDataArray = (notearray,templates) => {
    console.log (notearray)
    let dataObject = {}   //build sparse object to start
    notearray.forEach((note,index) => {
        Object.keys(note.data).forEach(cat =>{
            dataObject[cat] = dataObject[cat] || {0:{text:templates[note.type].categorynames[cat],bold:true}}
            Object.keys(note.data[cat]).forEach(field => {
                if(Array.isArray(note.data[cat][field])) {              //only compare select fields which are all arrrays even if single
                    dataObject[field] = dataObject[field] || {0:templates[note.type].fields[cat].fieldarray[field].title}
                    dataObject[field][index + 1] = note.data[cat][field].toString()
                }
            })
        })

    })
    let dataArray = [] //fill out array with with pdf rows
    Object.entries(dataObject).forEach(rowentry => {
        let row = []
        for (let i = 0; i < notearray.length + 1; i++) {
            if(rowentry[1][i])
                row.push(rowentry[1][i])
            else
                row.push("")
        }
        dataArray.push(row)
    })
return (dataArray)
}
const printPDF = async (compareObject,notearray,templates,action,email) => {
    console.log('please dont be here on rerender',notearray,compareObject,action,templates)
    let notecontent=[]
    let tableheader = [""]
    let tablewidths = ["auto"]
    notearray.forEach((note,index) => {
        tableheader.push({text:"Note "+(index+1),bold:true})
        tablewidths.push("*")
        console.log(note)
        notecontent.push({columns: [
                {text:"Note "+(index+1).toString()+": ",width:40},
                {svg:templates[note.type].svg,width:10},
                {text:note.title +" " + stringReverseDate(note.rtime)}
                ]})
        })
    console.log(tablewidths)
    let tablearray = pdfBuildDataArray(notearray,templates)
    tablearray.unshift(tableheader)
  console.log('table',tablearray)
    notecontent.push(
    {table: {
        headerRows: 1,
            widths:tablewidths,
            body: tablearray
    },layout: {
        hLineWidth: function(i, node) {
            return (i === 0 || i === node.table.body.length) ? 1 : 1;},
        vLineWidth: function(i, node) {
            return (i === 0 || i === node.table.widths.length) ? 1 : 1;},
        hLineColor: function(i, node) {return  'lightgray';},
        vLineColor: function(i, node) {return  'lightgray';},
        paddingTop: function(i, node) { return 2; },
        paddingBottom: function(i, node) { return 2; }
    }}
    )
        /*
    <Table responsive="md" className={"show"} size="sm">
        <thead>
        <tr>
            <th style={{width:"20%"}}></th>
            {noteArray.map((x,index) => <th>Note: {index+1}</th> )}
        </tr>
        </thead>
        <tbody>
        {noteDataArray}
        </tbody>
    </Table>*/

    let docDefinition = {
        content: notecontent,
        header: {
            margin:[10,15,10,10],
            columns: [
                {text:"Compare Notes",fontSize:13,margin:[4,0]},
                {text: [{text: 'Somm',color:'maroon'},'App'], alignment: 'right',fontSize:14}
            ]
        },
        footer: function(currentPage) {
            return {
                margin:[10,5,10,10],
                columns: [
                    {text: 'sommapp.com',fontSize:10},
                    {text: currentPage.toString(),fontSize:10,alignment:'right'}
                ]
            };
        },
        defaultStyle: {
            font: 'Roboto',
            fontSize:10
        }
    }
    switch(action) {

        case 'save':

            console.log('pdfsave',docDefinition)

            fetch('https://us-central1-sommapp2.cloudfunctions.net/generatePdf', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({doc:docDefinition,filename:"compare.pdf"})

            }).then(response => response.blob())
                .then(blob =>{
                    console.log('pdf blob',blob)
                    fileDownload(blob,"compare.pdf")
                })
                .catch(e => console.error(e))
            break;
        case   'print':
            // Need to figure this out with PDF running in functions now
            break;
        case 'email':
            const message = 'Comparison generated using <a href="https://sommapp.com"><span style="font-family:Helvetica"><span style="color:maroon;">Somm</span><span style="color:grey;">App </span></span></a><br><br>';
            const subject = 'Comparison of Tasting Notes' ;
            fetch('https://us-central1-sommapp2.cloudfunctions.net/emailPdf', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: email, subject: subject, message: message, doc:docDefinition,filename:"compare.pdf"}
                )
            })
            break;

    }
/*
    switch(action) {

        case 'save':
            pdfMake.createPdf(docDefinition).download("Compare")
            break;
        case   'print':
                //not working with remote
            break;
        case 'email':

            pdfMake.createPdf(docDefinition).getBase64(function (encodedString) {
                const message = 'Comparison generated using <a href="https://sommapp.com"><span style="font-family:Helvetica"><span style="color:maroon;">Somm</span><span style="color:grey;">App </span></span></a><br><br>';
                const subject = 'Comparison of Tasting Notes' ;
                const url = 'https://us-central1-sommapp2.cloudfunctions.net/sendPDFMail';

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({email: email, subject: subject, message: message, pdf: encodedString,filename:"compare"}
                    )
                })
            })
            break;

    }
*/
}





/*                <Modal show={showCompare} id={"showcomparemodal"} onHide={()=>handleCompareClose()} dialogClassName={"comparenotes-modal"} size={"xl"}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                        <ShowCompare notekeys={notekeys} templates={templates} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>handleCompareClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

 */
