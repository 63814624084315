import React, {useEffect, useState} from "react";
import {Alert,Spinner,Form,Button,FormGroup,Modal,InputGroup,Row,Col,Toast,ToastBody} from "react-bootstrap"
import {update, serverTimestamp, ref, onValue} from "firebase/database"
import {Chat,Grid3x3Gap,Trash,People,ClipboardPlus } from 'react-bootstrap-icons';
import {ShowRoom} from "./showroom"
import {FSloadNote,FSaddNote} from "./firestore";
import {useParams} from "react-router-dom";
import {getTime} from "./generalfunctions";
import {getDatabaseDb} from "./App";

export const TastingRooms = ({userInfo,joinRooms,templates}) => {
    let {joinroom} = useParams();
    console.log('entertr',userInfo,joinRooms,joinroom)
    const [room, setRoom] = useState({ roomname: '' });
    const [showLoading, setShowLoading] = useState(false);
    //const [rooms,setrooms] = useState ([])
    //const [joinrooms,setjoinrooms]  = useState([])
    const [showToast,setshowToast] = useState(false)
    const [toastRoom,settoastRoom] = useState('')
    const [joinRoomName,setjoinRoomName] = useState('')
    const [showRoomAdd,setshowRoomAdd] = useState(false)
    const [deleteItem,setdeleteItem] = useState (null)
    const [showRoomJoin,setshowRoomJoin] = useState(false)
    const [showRoom,setshowRoom] = useState(false)
    const [selectedRoom,setselectedRoom] = useState(null)
    const db =getDatabaseDb()

    useEffect(()=>{
        if (userInfo.uid && joinroom) {
            setjoinRoomName(joinroom)
            setshowRoomJoin(true)
        }
    },[joinroom,userInfo.uid])

const copyClipboard = (roomName) => {
    navigator.clipboard.writeText(roomName).then(()=> {
        settoastRoom(roomName)
        setshowToast(true)
    })
}

const saveRoom = (roomName) => {
        console.log(roomName)
    setShowLoading(true);
    const roomRef = ref(db, '/rooms/'+roomName+'/updateTime')
        onValue(roomRef,(snapshot) => {
        if (snapshot.exists()) {
            console.log('exists')
            return (
                <div>
                    <Alert color="primary">
                        Room name already exist!
                    </Alert>
                </div>
            );
        } else {
            let roomData = {author:userInfo.uid,updateTime:serverTimestamp(),messageCount:0}
            let myRoomsData = {createTime:serverTimestamp()}
            let updates = {}
            updates['/rooms/' + roomName] = roomData
            updates['/users/' + userInfo.uid + '/joinRooms/' + roomName] = myRoomsData
            update(ref(db),updates);
            setShowLoading(false);
        }
    },{
            onlyOnce: true
        })
}

const onChangeAdd = (e) => {
    e.persist();
    setRoom({...room, [e.target.name]: e.target.value});
}

const onChangeJoin = (e) => {
    e.persist();
    setjoinRoomName(e.target.value);
}

const deleteRoom = (room) => {
    console.log('deleteroom',room)
    let updates = {}
    updates['/rooms/' + room.roomName] = null
    updates['/users/' + userInfo.uid + '/joinRooms/' + room.roomName] = null
    console.log(updates)
    update(ref(db),updates).then((e)=>console.log('fsreturn',e));
    setdeleteItem(null)
}

const joinRoom = (roomName) => {
    console.log('joinroom',roomName)
    const roomRef = ref(db,'/rooms/'+roomName+'/updateTime')
    onValue(roomRef, (snapshot) => {
        if (!snapshot.exists()) {
            console.log('exists')
            return (
                <div>
                    <Alert color="primary">
                        Room name does not exist
                    </Alert>
                </div>
            );
        } else {
            let joinRoomsData = {createTime:serverTimestamp()}
            let updates = {}
            updates['/users/' + userInfo.uid + '/joinRooms/' + roomName] = joinRoomsData
            updates['/rooms/' + roomName + '/members/'+ userInfo.uid ] = {nickName:userInfo.info.nickName}
            update(ref(db),updates);
        }
    },{
        onlyOnce: true
    })
}

const sendMessage=(room) =>{
    console.log('send',room)
    const message = encodeURIComponent("I'm inviting you to SommApp tasting room "+room.roomName+". Link https://sommapp2.web.app/rooms/"+room.roomName)
    window.location.href = "sms:&body="+message
    }

return (
    <div>
                <Button variant="outline-primary" className={"mb-3"} onClick={()=>setshowRoomAdd(true)}>
                Start Room
                </Button> &nbsp;
                <Button className={"mb-3"} variant="outline-primary"  onClick={()=>setshowRoomJoin(true)}>
                    Join Room
                </Button>
            <Toast onClose={() => setshowToast(false)} show={showToast} delay={3000} autohide>
                <ToastBody style={{color:"maroon"}}>Copied {toastRoom} to Clipboard</ToastBody>
            </Toast>


        <h5 className={"pt-3"}>Tasting Rooms</h5>
        <div>
            {joinRooms.map((room,index)=> (
                    <Row className="room_row " key={index}>
                        <Col xs={3} key={1}>{room.roomInfo.owner ? <span>*</span> : <span></span>} <span onClick={() => {setselectedRoom(room); setshowRoom(true)}}>{room.roomName}</span> &nbsp;<ClipboardPlus onClick={()=>copyClipboard(room.roomName)}/></Col>
                        <Col xs={1} key={2} >
                            <span><People size={20} onClick={() => {setselectedRoom(room); setshowRoom(true)}}/> </span>
                        </Col>
                        <Col xs={1} key={3} >
                            <span><Chat size={20} onClick={() => {sendMessage(room); }}/> </span>
                        </Col>
                        <Col xs={1} key={4}>
                            <span ><Trash size={20} onClick={()=> {setdeleteItem(room) }}/></span>
                        </Col>
                    </Row>
                )
            )}
        </div>

    <Modal show={showRoomAdd} id={"addmodal"} onHide={()=>setshowRoomAdd(false)} >
        {showLoading &&
        <Spinner color="primary" />
        }
        <Modal.Body>
            <Form.Label>New Tasting Room Name</Form.Label>
            <InputGroup>
                <InputGroup.Text>{userInfo.info.uniqueId}-</InputGroup.Text>
                <Form.Control type="text" name="roomname" id="roomname" placeholder="Enter Room Name" value={room.roomname} onChange={(e)=>{onChangeAdd(e)}} />
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={()=>setshowRoomAdd(false)}>
                Cancel
            </Button>
            <Button variant="outline-primary" onClick={()=>{saveRoom(userInfo.info.uniqueId+'-'+room.roomname); setshowRoomAdd(false)}}>
                Add
            </Button>
        </Modal.Footer>
    </Modal>

    <Modal show={showRoomJoin} id={"joinmodal"} onHide={()=>setshowRoomJoin(false)} >
        {showLoading &&
        <Spinner color="primary" />
        }
        <Modal.Body>
            <Form.Label>Join A Tasting Room</Form.Label>
            <InputGroup>
                  <Form.Control type="text" name="roomname" id="roomname" placeholder="Room Name (including 7 digit code with dash)" value={joinRoomName} onChange={(e)=>onChangeJoin(e)} />
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={()=>setshowRoomJoin(false)}>
                Cancel
            </Button>
            <Button variant="outline-primary" onClick={()=>{joinRoom(joinRoomName) ;setshowRoomJoin(false)}}>
                Add
            </Button>
        </Modal.Footer>
    </Modal>

    <Modal show={showRoom} id={"showroommodal"} onHide={()=>setshowRoom(false)} dialogClassName={"showroom-modal"} size={"xl"}>
        <Modal.Body>
            <ShowRoom userInfo={userInfo} room={selectedRoom} templates={templates}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={()=>setshowRoom(false)}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
    <ConfirmModal message={"Are you sure you want to delete room?"} task={deleteRoom} item={deleteItem}/>
    </div>
)
}
const ConfirmModal = ({message,task,item}) => {

    const [show,setshow] = useState(false)
    useEffect(()=>{
        if (item) setshow(true)
    },[item])
    console.log("modal",item,show)
    return (
        <Modal show={show} id={"confirmmodal"} onHide={()=>setshow(false)} >
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={()=>setshow(false)}>
                    Cancel
                </Button>
                <Button variant="outline-primary" onClick={()=>{task(item);setshow(false)}}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const addNoteToRoom = (userInfo,notekey,roomName,title,blind) => {
    console.log('addnotetoroom',notekey,roomName)
    let sharekey = userInfo.info.uniqueId + '-' + notekey
    const shareTitle = blind? "Blind Note" : title
    let noteInfo = {author:userInfo.info.uniqueId,nickName:userInfo.info.nickName,addTime:serverTimestamp(),title:shareTitle,key:sharekey,blind:blind}
    FSloadNote(notekey,"users/"+userInfo.uid+"/notes/")
        .then(notedata => {
            if (notedata) {
                console.log("load notedata", notedata, notekey,)
                let updates = {}

                console.log('addnote',noteInfo)
                updates['/rooms/' + roomName +'/noteTable/' + sharekey] = noteInfo
                const db = getDatabaseDb()
                update(ref(db),updates);
                notedata.key = sharekey
                notedata.blind = blind? true : false
                notedata.shareTime = getTime()
                FSaddNote(notedata,"shared/"+roomName+"/notes/")
            }
            else {
                //set error
            }
        })

}
