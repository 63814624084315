import React, {useState} from "react";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import {Eye,EyeSlash} from "react-bootstrap-icons";
import validator from "validator";
import {getAuthRef} from "./App"
import {signInWithEmailAndPassword,createUserWithEmailAndPassword} from "firebase/auth";

export function SignIn({showLogin,setShowLogin, userInfo,setUserInfo}) {

    const [passwordType,setPasswordType] = useState("password")
    const [goodEmail,setGoodEmail] = useState (false)
    const [goodPass,setGoodPass] = useState(false)
    const [emailAddress,setEmailAddress] = useState( "")
    const [password,setPassword] = useState( "")
    const [showCreate,setShowCreate] = useState(false)


    const setValidateEmail  = function (email) {
        if (validator.isEmail(email)) {
            setGoodEmail(true)
        }
        else {
            setGoodEmail(false)
        }
        console.log('email',email)
        setEmailAddress(email)
    }
    const setValidatePassword  = function (pass) {
        if (validator.isStrongPassword(pass)) {
            setGoodPass(true)
        }
        else {
            setGoodPass(false)
        }
        setPassword(pass)
    }
    function handleEmailLogin () {
        console.log("handle login",auth)

        signInWithEmailAndPassword(auth, emailAddress, password)
            .then((userCredential) => {
                // Signed in
                console.log("signed in new")
                // ...
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/user-not-found':
                        alert("Email not found")
                        break
                    case 'auth/wrong-password':
                        alert("Password not correct")
                        break
                    default:
                        alert('Error logging in:' + error.message)
                }
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("signin error",errorMessage)
            })
    }    function handleCreateLogin () {
        console.log("handle login",auth)

        createUserWithEmailAndPassword(auth, emailAddress, password)
            .then((userCredential) => {
                // Signed in
                console.log("signed in new")
                // ...
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/user-not-found':
                        alert("Email not found")
                        break
                    case 'auth/wrong-password':
                        alert("Password not correct")
                        break
                    default:
                        alert('Error logging in:' + error.message)
                }
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("signin error",errorMessage)
            })
    }
    const modelSign = {
        title: "SignIn",
        showModel: showLogin,

    }

    const auth = getAuthRef()
    console.log("signin auth",auth)
    return (
        <div>
        <Button variant="outline-primary" onClick={()=>setShowLogin(true)}>SignIn</Button> <Button onClick={()=>setShowCreate(true)} variant="outline-primary">SignUp</Button>
        <Modal show={showLogin} id={"emailmodal"} onHide={()=>setShowLogin(false)} >
            <Form noValidate>
                <Modal.Title>Signin</Modal.Title>
                <Modal.Body>

                    <Form.Group controlId="emailAddress" hasvalidation>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control isInvalid={!goodEmail} isValid={goodEmail} type="email" name="emailAddress"  placeholder="Enter email address" value={emailAddress} onChange={(e)=> setValidateEmail(e.target.value)} />
                        <Form.Control.Feedback  type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">
                            Good email
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password" hasvalidation>
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                            <Form.Control isInvalid={!goodPass} isValid={goodPass} type={passwordType} name="password"  placeholder="Password" value={password} onChange={(e)=> setValidatePassword(e.target.value)} />
                            <Form.Text><span onClick={() => setPasswordType((passwordType === 'password')?'text':'password')}> &nbsp; {(passwordType==='password')?<Eye size={24}/>:<EyeSlash size={24}/>}</span></Form.Text>

                            <Form.Control.Feedback  type="invalid">
                                Make sure to use at least one lower and upper case letter, number and special symbol.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">
                                Good Password
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={()=>setShowLogin(false)}>Cancel</Button>
                    <Button variant="outline-primary"  onClick={()=>{handleEmailLogin(); setShowLogin(false)}}>Yes</Button>
                </Modal.Footer>
            </Form>
        </Modal>
            <Modal show={showCreate} id={"createmodal"} onHide={()=>setShowCreate(false)} >
                <Form noValidate>
                    <Modal.Body>

                        <Form.Group controlId="emailAddressc" hasvalidation>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control isInvalid={!goodEmail} isValid={goodEmail} type="email" name="emailAddressc"  placeholder="Enter email address" value={emailAddress} onChange={(e)=> setValidateEmail(e.target.value)} />
                            <Form.Control.Feedback  type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">
                                Good email
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="passwordc" hasvalidation>
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={!goodPass} isValid={goodPass} type={passwordType} name="passwordc"  placeholder="Password" value={password} onChange={(e)=> setValidatePassword(e.target.value)} />
                                <Form.Text><span onClick={() => setPasswordType('text')}><Eye size={24}/></span></Form.Text>

                                <Form.Control.Feedback  type="invalid">
                                    Make sure to use at least one lower and upper case letter, number and special symbol.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Good Password
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={()=>setShowCreate(false)}>Cancel</Button>
                        <Button variant="outline-primary"  onClick={()=>{handleCreateLogin(); setShowCreate(false)}}>Yes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )



}
