import React, {useState,useMemo,useEffect,useRef} from "react";
import {useParams} from "react-router-dom";
import {Tabs,Tab,Form,Row,Col,Modal,Button,Image} from "react-bootstrap";
import Select from 'react-select'
import { Pencil, PencilFill,Keyboard} from 'react-bootstrap-icons';
import {getLibraryOptions,addLibraryTerm} from './App'
import {writeUserInfo} from "./firestore";
import { Prompt } from 'react-router'

export const EditTemplate =({templates,userInfo,setUserInfo})=>{

    let {type} = useParams();
    const [category,setCategory] = useState(null)
    const [categoryOptions,setCategoryOptions] = useState([])
    const [fieldOptions,setFieldOptions] = useState([])
    const [field,setField] = useState(null)
    const [fieldOptionInfo,setFieldOptionInfo] = useState({})
    const [extraOptions,setExtraOptions] = useState({})
    const [categoryValue,setCategoryValue] = useState ({})
    const [fieldValue,setFieldValue] = useState ({})
    const [isChanged,setIsChanged] = useState(false)
    console.log ('enterTemplates',type,category,field,fieldOptionInfo,extraOptions,userInfo)
    const reg= /\s*(?:,|$)\s*/

    useEffect(()=>{
        console.log('useEffect')
        if (category && field) {
            const optionKeys = templates[type].fields[category].fieldarray[field].options
            const optionTemplate = optionKeys[0]
            const optionKey = optionKeys[1]
            const optionInfo = templates[optionTemplate].options[optionKey]
            setFieldOptionInfo(optionInfo)
            setFieldValue({"value":field,"label":templates[type].fields[category].fieldarray[field].title})
            console.log('optioninfo' ,optionInfo)
        }
        else setFieldValue(null)

        if (category && field && userInfo.customTemplateOptions[type] && userInfo.customTemplateOptions[type][category] && userInfo.customTemplateOptions[type][category][field]) {
            setExtraOptions(userInfo.customTemplateOptions[type][category][field])
            console.log(userInfo.customTemplateOptions[type][category][field],extraOptions)
             }
        else {
            setExtraOptions({})
        }
    },[field])

    useEffect(()=>{
        if (category) {
            let optionArray = []
            templates[type].fields[category].fieldorder.forEach((field) => {
                if (["sm","sr","sb","ss"].includes(templates[type].fields[category].fieldarray[field].style) )
                optionArray.push({value: field, label: templates[type].fields[category].fieldarray[field].title})
            })
            setFieldOptions(optionArray)
            setField(null)
            setFieldOptionInfo({})
            setCategoryValue({value:category,label:templates[type].categorynames[category]})
            console.log(optionArray)
        }
        else {
            setFieldOptions([])
            setCategoryValue(null)
        }
    },[category])

    useEffect(()=>{
        if (type) {
            let optionArray = []
            templates[type].categories.forEach((cat) => {
                optionArray.push({value: cat.key, label: cat.name})
            })
            setCategoryOptions(optionArray)
            setFieldOptions([])
            setCategory(null)
            setCategoryValue(null)
            console.log(optionArray)
        }
    },[type])

    const saveOptions =()=> {
        if (!userInfo.customTemplateOptions[type]) userInfo.customTemplateOptions[type]={}
        if (!userInfo.customTemplateOptions[type][category]) userInfo.customTemplateOptions[type][category]={}
        userInfo.customTemplateOptions[type][category][field] = extraOptions
        console.log("saveOptions",userInfo)
        writeUserInfo(userInfo,setUserInfo)
    }
    const buildOptions =(value,label)=> {
        const addObject = {}
        addObject[label] = value.split(reg)

        setExtraOptions({...extraOptions,[label]:value.split(reg)})
    }
    const checkSaved=()=>{
        if (!isChanged) return true
        if (window.confirm('You have unsaved Data.  It will be lost if you change selection') ) {
            setIsChanged(false)
            return true
        }
        else return false
    }

    if (type) {
    return (
        <div>
            <Prompt when={isChanged} message="You have unsaved changes.  Are you sure you want to leave this page?"/>

            <div>You can add extra terms to your templates.  Terms should be as short as possible.  Seperate terms with commas. Note some option fields are used in multiple note types.</div>
            <Row>
            <Col>
                <SingleSelect title={"Select Category"} selectoptions={categoryOptions} updatefunction={setCategory} option={categoryValue} checkSaved={checkSaved}/>
            </Col>
            <Col>
                <SingleSelect title={"Select Field"} selectoptions={fieldOptions} updatefunction={setField} option={fieldValue} checkSaved={checkSaved}/>
            </Col>
            <Col>
                <Button variant={(isChanged) ? "primary":"outline-secondary"} onClick={()=>{console.log('save'); saveOptions(); setIsChanged(false)}}>
                    Save
                </Button>
            </Col>

            </Row>
            {(fieldOptionInfo.title) ?
            <Form.Group controlId="optionsInput">
                <Form.Label>{fieldOptionInfo.title} <span style={{fontSize:".8em"}}>(used in {fieldOptionInfo.used})</span></Form.Label>
                    {fieldOptionInfo.optArr.map((optObject)=>{
                        return (
                            <Form.Group controlId="options-">
                                {(optObject.label !== " ") ? <Form.Label>{optObject.label}:</Form.Label> : ""}
                                <Form.Control value={(extraOptions[optObject.label]) ? extraOptions[optObject.label].join(", ") : ""} onChange={(e)=>{buildOptions(e.target.value,optObject.label); setIsChanged(true)}} type="text" placeholder="Enter Additional Options" />
                                <Form.Text className="text-muted">
                                    {optObject.options.join(', ')}
                                </Form.Text>
                            </Form.Group>
                        )
                    })}
            </Form.Group>
                :''}
</div>
    )
}
else {
        return null

    }


}
const SingleSelect = React.memo(({title,selectoptions,option,updatefunction,checkSaved}) => {
        console.log("SingleSelect",title,selectoptions)

    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#999',
            'fontSize':'.9rem'
        }),
    }
    return (
        <Select
            isClearable
            isSearchable={false}
            placeholder ={title}
            customStyles = {customStyles}
            value = {option}
            options = {selectoptions}
            onChange={(valobj) => {if (checkSaved()) updatefunction(valobj ? valobj.value : null)}}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'rgba(128,0,0,1)',
                    primary25:'rgba(128,0,0,.25)',
                    primaty50:'rgba(128,0,0,.50)',
                    primary75:'rgba(128,0,0,.75)',
                },
            })}
        />)
})
