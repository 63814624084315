import React from "react";

export function returnSvg (svg) {
    return (
      <span dangerouslySetInnerHTML={{ __html: svg }} />
    )
}

export function buildNoteTitle(note,template) {
    let title = ''
    if (note['customtitle']){title=note.data.info.title}
    else {
        template.tkey.map((key,index) => {
            if (note.data[key.cat][key.f]) (index>0)? title += ', ' + note.data[key.cat][key.f] : title += note.data[key.cat][key.f]
        })
    }
    return title
}
export function stringDate (time){
    let date = new Date(time)
    return date.toLocaleDateString()
}
export function stringReverseDate (reversetime){

    let time = (9999999999999 - reversetime)*1000
    let date = new Date(time)
    return date.toLocaleDateString()
}
export function getReverseTime () {
    return(9999999999999-Math.round(Date.now() / 1000))
}
export function getTime() {
    return(Math.round(Date.now() / 1000))
}
let locations = []  //temp needs to go to local storage
export async function getLocation() {
    let geo = {}
    try {
         geo = await new Promise((res, rej) => {
            navigator.geolocation.getCurrentPosition(res, rej);
        })
    } catch (err) {
        console.log('geo error',geo,err)
        return ({"coords":{"lat":0,"long":0},"loc":"None - Geolocation not working"})
    }
    let location ={}
    const lat = Math.round(geo.coords.latitude*100000)/100000
    const long =Math.round(geo.coords.longitude*100000)/100000
    const lockey = lat.toString()+"_"+long.toString()
    console.log(lockey,locations)
    if (lockey in locations)
        location = locations[lockey]
    else {
          //supposedly free reverse GEO no account needed only from client browsers.  City level only
        const biglocation = await fetch('https://api.bigdatacloud.net/data/reverse-geocode-client?latitude='+lat+'&longitude='+long+'&localityLanguage=en')
            .then(response => response.json())
        /*
        let biglocation ={} //temp while developing
        biglocation.locality = "Point Venture"
        biglocation.principalSubdivion = "Texas"
        biglocation.postcode = "78645" */
        location = {"city":biglocation.locality,"state":biglocation.principalSubdivision,"zip":biglocation.postcode}
        const keys = Object.keys(locations);
        if (keys.length > 3) delete locations[keys[0]];
        console.log(keys,lockey,location,biglocation)
        locations[lockey] = location;
        //set localstorage
    }

    return ({"coords":{"lat":lat,"long":long},"loc":location})
}

//unique Id is an easy to type unique ID for up to 100000 users
export function getUniqueId (userNumber) {
    const code="CHTZDNFMPBLRJSVWYGXK"; //20 random and safe on spelling, last few only used by random letter
    let userB9 = "000000" + userNumber;
    userB9 = userB9.substr(userB9.length-5);
    let user0 = userB9.charAt(4);
    let code0 = code.charAt(user0);
    let code1 = code.charAt(userB9.charAt(2));
    let numbers = '';
    if (user0 % 2) numbers = userB9.charAt(3) + userB9.charAt(0) + userB9.charAt(1) + Math.floor(Math.random() * 10);
    else  numbers = userB9.charAt(0) + userB9.charAt(1) + Math.floor(Math.random() * 10) + userB9.charAt(3);
    let random = Math.floor(Math.random() * 20);
    let code2 = code.charAt(random);
    return  code0 + code1 +code2 + numbers;

}
export function isIos() {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        return true;
    } else {
        return navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 2 &&
            /MacIntel/.test(navigator.platform);
    }
}

export const isInStandaloneMode = () =>
    'standalone' in window.navigator && window.navigator.standalone;
