import React, {useEffect, useState} from "react";
import {Alert,Spinner,Form,Button,FormGroup,Modal,InputGroup,Container,Row,Col,Toast,ToastBody,Card,Dropdown,DropdownButton} from "react-bootstrap"
import { People,ClipboardPlus,FileText,Trash } from 'react-bootstrap-icons';
import {ShowNote} from "./shownote";
import {FSloadNote} from "./firestore";
import {getDatabaseDb} from "./App";
import {ref, onValue, serverTimestamp, push, set } from "firebase/database";

export const ShowRoom = ({userInfo, room,templates}) => {
    const [members,setMembers] = useState([])
    const [messages,setMessages] = useState([])
    const [notes,setNotes] = useState([])
    const [message,setMessage] =useState('')
    const [noteKey,setNoteKey] =useState(null)
    const [modalType,setModalType] = useState('')
    const [blind,setBlind] = useState(false)
    const [noteData,setNoteData] = useState(null)

    const db = getDatabaseDb()
    const FBmessagesref = ref(db, '/rooms/'+ room.roomName + '/messages')
    const nickName= userInfo.info.nickName
    const uniqueId = userInfo.info.uniqueId
    useEffect(()=>{
        if (room) {
            const FBmembersref = ref(db, '/rooms/'+ room.roomName + '/members')
            const FBnotesref = ref(db, '/rooms/'+ room.roomName + '/noteTable')

            onValue(FBmembersref, (snapshot) => {
                const data = snapshot.val()
                const membersArr = []
                if (data) {
                    Object.entries(data).forEach((entry) => {
                        const [key, value] = entry
                        console.log (key,value)
                        membersArr.push(value.nickName)
                    })
                }
                setMembers(membersArr)
            })
            onValue(FBmessagesref, (snapshot) => {
                const data = snapshot.val()
                const messagesArr = []
                if (data) {
                    Object.entries(data).forEach((entry) => {
                        const [key, value] = entry
                        console.log (key,value)
                        messagesArr.push(value)
                    })
                }
                console.log(messagesArr)
                setMessages(messagesArr)
            })
            onValue(FBnotesref, (snapshot) => {
                const data = snapshot.val()
                const noteArr = []
                if (data) {
                    Object.entries(data).forEach((entry) => {
                        const [key, value] = entry
                        console.log (key,value)
                        noteArr.push(value)
                    })
                }
                console.log(noteArr)
                setNotes(noteArr)
            })
        }
    },[room])

    const showSharedNote=(key)=>{
        FSloadNote(key,"shared/"+room.roomName+"/notes/")
            .then(notedata => {
                if (notedata) {
                    console.log("load notedata", notedata, key,)
                    setNoteData(notedata)
                    setNoteKey(key)
                    setModalType("show")
                } else {
                    console.log('compare read error')
                    //set error
                }
            })

        console.log('startshow',key)
    }
    const onChangeMessage = (e) => {
        e.persist();
        setMessage(e.target.value);
    }
    const submitMessage = (e) => {
        e.preventDefault();
        const messageObj = {
            u:nickName,
            m:message,
            id:uniqueId,
            t:serverTimestamp()
        }
        let newRef = push(FBmessagesref)
        set(newRef,messageObj)
        console.log(message,newRef)
        setMessage('')
    }

console.log('showroom',room)
    if (members) {
        return (
            <div>
                <h5><People size={24}/>&nbsp;{room.roomName}</h5>

                <Row>

                    <Col sm={2}>
                        <DropdownButton className={"member-dropdown"} variant="outline-primary" id="members" title="Members">
                            {members.map((member)=>(
                                    <Dropdown.ItemText>
                                        {member}
                                    </Dropdown.ItemText>
                                )
                            )}
                        </DropdownButton> </Col>
                    <Col sm={1}></Col>
                    <Col >
                        <DropdownButton variant="outline-primary" id="notes" title="Attached Notes">
                            {notes.map((note)=>(
                                    <Dropdown.ItemText>
                                        <span>{note.nickName}</span> - <span>{note.title}</span> &nbsp; <FileText onClick={()=>{setBlind(note.blind); showSharedNote(note.key); }}/>
                                    </Dropdown.ItemText>
                                )
                            )}
                        </DropdownButton></Col>
                </Row>
                    <div style={{paddingTop:"15px"}}>
                        <Form className="MessageForm" onSubmit={submitMessage}>
                        <InputGroup>
                            <Form.Control type="text" name="message" id="message" placeholder="Type short message and return" value={message} onChange={(e)=>onChangeMessage(e)} />
                        </InputGroup>
                        </Form>
                    </div>
                    <Container>
                        {messages.slice().reverse().map((message)=>(
                                <Row >
                                <Col md="auto" style={{maxWidth:"80%"}} className={message.id===uniqueId? "chatmessage owner": "chatmessage guest"}>
                                   {message.u}: {message.m}
                                </Col>
                                    <Col md="2" style={{fontSize:".7em",color:"grey",paddingTop:"15px"}}>
                                         {new Date(message.t).toLocaleString()}
                                    </Col>
                                </Row>
                            )

                        )}
                    </Container>
                <Modal show={modalType==="show"} id={"shownotemodalrooms"} onHide={()=>setModalType(false)} dialogClassName={"shownote-modal"} size={"xl"}>
                    <Modal.Body>
                        <ShowNote noteData={noteData} templates={templates} path={"shared/"+room.roomName+"/notes/"} blind={blind} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={()=>setModalType(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
    else return (<div>Loading</div>)
}


