
import firebase from "firebase/compat/app";
import {collection, addDoc, getDoc, getDocFromCache ,setDoc, doc, query, where, getDocs, limit, startAt, deleteDoc,increment} from "firebase/firestore";
import {setLibraryOptions, getDefaultUserInfo, getFirestoreDb} from "./App";
import {getUniqueId} from "./generalfunctions"
import { getStorage, ref, deleteObject} from "firebase/storage";

export async function FSaddNote (note,path) {
    console.log('FS',note)
    const FsDb = getFirestoreDb()
    const docRef = doc (FsDb,path,note.key)

        await setDoc(docRef, note).catch(e=>console.log(console.log("write fs note error",note,path,e)))

    /*
    const db = firebase.firestore()
    const dbref = db.doc(path+note.key);
    console.log(dbref)
    dbref.set(note)
        .then(()=>{
            console.log("Note Written to FS")
        })
        .catch((error) => {
            console.error("error writing note: ",error)
        })
*/
}
/*
export function FSloadNotes (keyarray,path) {
    const db = firebase.firestore()
    const dbref = db.collection(path);
    return (
    dbref.where(firebase.firestore.FieldPath.documentId(), 'in', keyarray).get()
        .then((docs) => {
            if (docs) {
                let resultarray=[]
                console.log('loadnotes', docs)
                docs.forEach(doc=> resultarray.push(doc.data()))

                return resultarray
            }
            else console.log('fucked up',docs)
        })
    )
}
*/
export async function FSqueryNotes(filters,path,qLimit,startDoc){
    const FsDb = getFirestoreDb()
    const notesRef = collection (FsDb,path)

    let notequery = query(notesRef)
    let filterslength = Object.keys(filters).length
    if (filterslength) {
        let whereArray = []
        Object.keys(filters).forEach(key => {   //generate a compound where... think about adding a weight for better filter
            console.log(key)
            console.log('where',where(filters[key].key,filters[key].op, filters[key].value))
            notequery = query(notequery,where( filters[key].key,filters[key].op, filters[key].value))
        })
        notequery = query(notequery,limit(qLimit+1))
    }
    else {
        notequery = query(notequery, where("key", "!=", "x"),limit(qLimit+1))
    }
    if (startDoc) notequery = query(notequery, startAt(startDoc))
    const querySnapshot = await getDocs(notequery);
    let noteArray = []
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        noteArray.push(doc.data())
        console.log(doc.id, " => ", doc.data());
    });
    let lastDoc = null
    console.log("querysnap",querySnapshot,querySnapshot.size)
    if (querySnapshot.size > qLimit) {
        lastDoc = querySnapshot.docs[querySnapshot.size-1]
        noteArray.pop()
        console.log("limit",querySnapshot,querySnapshot.docs)
    }
    return {noteArray,lastDoc}
    /*const db = firebase.firestore()
    let notesRef = db.collection(path)
    let notequery = notesRef
    let filterslength = Object.keys(filters).length
    if (filterslength) {
        Object.keys(filters).forEach(key => {   //generate a compound where... think about adding a weight for better filter
            console.log(key)

                notequery = notequery.where( filters[key].key,filters[key].op, filters[key].value).limit(limit+1)

        })
    }
    else {
         notequery = notequery.where("key", "!=", "x").limit(limit+1)
    }
    if (startDoc) notequery = notequery.startAt(startDoc)
    console.log('notequery',notequery)
    return(
    notequery.get()
        .then((snapShot) => {
            if (snapShot) {
                let noteArray = []
                snapShot.forEach(doc=> noteArray.push(doc.data()))
                console.log('FSquerynotes', snapShot)
                let lastDoc = null
                if (snapShot.docs.length > limit) {
                    lastDoc = snapShot.docs[snapShot.docs.length-1]
                    noteArray.pop()
                }
                console.log(lastDoc)
                return {noteArray,lastDoc}
            }
            else {
                console.log('fucked up', snapShot)
                return []
            }
        })
    )*/
}


export async function FSloadNote (key,path) {
    const fsDb = getFirestoreDb()
    const docRef = doc(fsDb, path, key)
    try {
        let docSnap = await getDocFromCache(docRef)
        if (docSnap.exists()) {
            console.log("FSload cache")
            return docSnap.data()
        }
    } catch(e) {
            let docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                console.log("FS load database.js")
                return docSnap.data()
            } else {
                console.log("no data")
                return null
            }
        }
 /*   const db = firebase.firestore()
    const noteref = db.doc(path+key)
    return(
    noteref.get({source: 'cache'})
        .then((doc) => {
        if (doc.exists) {
            console.log("FSload cache")
            return doc.data()
        } else {
            return null
        }
    }).catch(() =>  noteref.get()
        .then((doc) => {
            if (doc.exists) {
                console.log("FSloadDB")
                return doc.data()
            } else {
                return null
            }
        }))
    )*/
}
export async function FSdeleteNote(key,path,uid) {

    console.log('delete',key,uid,path)
    //const notekey=key
    const storage = getStorage()

    let deleteRef = ref(storage,"/images/" + uid + "/" + key + "/image")
    deleteObject(deleteRef)
        .then(function () {
            console.log('file deleted')
        })
        .catch(function (error) {
            console.error('filedelete', error);
        })
    deleteRef = ref(storage, "/images/" + uid + "/" + key + "/file1")
    deleteObject(deleteRef)
        .then(function () {
            console.log('file deleted')
        })
        .catch(function (error) {
            console.error('filedelete', error);
        })

    const FsDb = getFirestoreDb()
    console.log('delete',uid,key)
    await deleteDoc(doc(FsDb, "users/" + uid + "/notes", key)).catch (e => {console.error("FS error deleting note: ",e)})
    console.log("Note deleted to FS")



    /*
    db.collection("users").doc(uid).collection("notes").doc(key).delete()
        .then(()=>{
            console.log("Note deleted to FS")
        })
        .catch((error) => {
            console.error("FS error deleting note: ",error)
        })
*/
}

export async function writeUserInfo  (userInfo) {
    const fsDb = getFirestoreDb()
    console.log('writeinfo',userInfo)
    //const db = firebase.firestore();
    const docRef = doc (fsDb,"users",userInfo.uid)
    await setDoc(docRef, userInfo).catch(e => {
        console.log("write userinfo error",userInfo,e)
        })

}

export async function loadUserInfo (setUserInfo,user) {

    const fsDb = getFirestoreDb()
    console.log('loadinfo - user',user.uid)
    let userInfo = {}
    //const db = firebase.firestore();
//    const docRef = db.collection("users").doc(user.uid)
    const docRef = doc(fsDb, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        userInfo = docSnap.data()
        if (!userInfo.info.userNumber) { //need to set this and increment the server count
            let docRef = doc(fsDb, "system", "info");
            const docSnap = await getDoc(docRef);
            console.log(docSnap)
            await setDoc(docRef, {userCount:increment(1)}, { merge: true })
            userInfo.info.userNumber = docSnap.userCount;
            userInfo.info.uniqueId = getUniqueId(userInfo.info.userNumber)
             docRef = doc (fsDb,"users",userInfo.uid)
            await setDoc(docRef, userInfo).catch(e => {
                console.log("write userinfo error",userInfo,e)
            })
            }

        setUserInfo(userInfo)
        console.log('setting user Info',userInfo)
        }
 else {
        // doc.data() will be undefined in this case
        console.log("No userInfo yet",);
        userInfo = getDefaultUserInfo(user)
        if (!userInfo.info.userNumber) { //need to set this and increment the server count
            let docRef = doc(fsDb, "system", "info");
            console.log(docRef)
            const docSnap = await getDoc(docRef);
            console.log(docSnap.data())

            await setDoc(docRef, {userCount:increment(1)},
                { merge: true }).catch(e=>console.log(console.log("write user error",e)))
            console.log('after inc count')
            userInfo.info.userNumber = docSnap.data().userCount;
            userInfo.info.uniqueId = getUniqueId(userInfo.info.userNumber)
            docRef = doc (fsDb,"users",userInfo.uid)
            await setDoc(docRef, userInfo).catch(e => {
                console.log("write userinfo error",userInfo,e)
            })
            console.log('userinfo written')
        }
        setUserInfo(userInfo)
        // need to do something with no user/new user
    }

/*
    docRef.get().then((doc) => {
     console.log("doc load",doc,user.uid)
    if (doc.exists) {
        console.log("Document data:", doc.data());
        userInfo = doc.data()
        console.log('update from db',userInfo)
        if (!userInfo.info.userNumber) { //need to set this and increment the server count
            db.collection('system').doc('info').get('userCount').then ((doc) => {

                db.collection('system').doc('info').update(
                    {'userCount':firebase.firestore.FieldValue.increment(1)
                    }).then(() => {console.log("increment user count down")
                });
                userInfo.info.userNumber = doc.data().userCount;
                userInfo.info.uniqueId = getUniqueId(userInfo.info.userNumber)
                writeUserInfo(userInfo)
                setUserInfo(userInfo)
                setuserId(userInfo.uid)
                console.log('setting user Info',userInfo)
            })
        }
        console.log('loaded good userInfo',userInfo)
         setUserInfo(userInfo)
        setuserId(userInfo.uid)
    } else {
        // doc.data() will be undefined in this case
        console.log("No userInfo yet");
        userInfo = getDefaultUserInfo(user)
        setUserInfo(userInfo)
        // need to do something with no user/new user
    }*/

     setLibraryOptions(userInfo)

    console.log('very end of get userInfo',userInfo)
return true
}



